import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const Wholesale = () => {
  const introData = {
    icon: "/assets/svg/industry/wholesale.gif",
    title: "Wholesale",
    desc: "Consistent Quality, Streamlined Operations",
    illustration: "/assets/svg/industry/wholesale-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/order-collaboration.png",
    desc: "Imagine revolutionizing your wholesale business with the power of Asset Management and automatic Purchase order. Our application is not just a tool, it's a game-changer that will elevate your operations to new heights of efficiency and profitability.",
    provides: [
      {
        icon: "icon-workflow",
        title: "Streamlined Ordering Process",
        desc: "Say goodbye to manual order processing and paperwork. Our SaaS application provides a seamless platform for managing orders, allowing your customers to place orders online easily. With automated order processing and real-time inventory updates, you can fulfill orders faster and with greater accuracy.",
      },
      {
        icon: "icon-security",
        title: "Security and Compliance",
        desc: "Rest easy knowing that your data is safe and secure with our SaaS application. We employ industry-leading security measures and compliance protocols to protect your sensitive information and ensure regulatory compliance.",
      },
      {
        icon: "icon-scaleup",
        title: "Scalability",
        desc: "As your business grows, our application grows with you. Whether you're expanding your product offerings, entering new markets, or increasing your customer base, our scalable solution can accommodate your evolving needs.",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate
        introData={introData}
        productDetails={productDetails}
        type="industry"
      />
      <Footer />
    </>
  );
};

export default Wholesale;
