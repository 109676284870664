import React, { useState } from "react";

import ctl from "@netlify/classnames-template-literals";

interface ButtonProps {
  id: string;
  variant?: string;
  children?: (string | JSX.Element)[] | JSX.Element | string | React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customClass?: string;
  stopPropagation?: boolean;
  key?: string;
}

const Button = ({
  id,
  variant = "primary",
  children,
  disabled = false,
  isLoading = false,
  onClick,
  customClass,
  stopPropagation = true,
  key,
}: ButtonProps) => {
  const isDisabled = disabled || isLoading;

  const getVariantClassName = () => {
    return ctl(
      `
        w-fit
        cursor-pointer

        ${
          variant === "primary" &&
          `
            border-0
            bg-b-400
            text-n-000
            px-[28px]
          `
        }

        ${
          variant === "secondary" &&
          `
            border
            border-solid
            border-b-400 bg-transparent text-b-400
            px-[28px]
          `
        }

        ${
          variant === "tertiary" &&
          !isDisabled &&
          `
            border-0
            bg-transparent
            text-b-400
          `
        }
      `
    );
  };

  const btnCN = ctl(`
    flex
    justify-center
    min-h-[44px]
    items-center
    whitespace-nowrap
    rounded-[40px]
    typography-h300-bold
    py-[10px]

    ${
      !isDisabled
        ? getVariantClassName()
        : "disabled cursor-not-allowed border-0 bg-n-200 text-n-000"
    }

    ${customClass}
  `);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    stopPropagation && e.stopPropagation();
    onClick && onClick(e);
  };

  return (
    <button
      id={id}
      key={key || id}
      className={btnCN}
      style={{ outline: "none" }}
      onClick={(e) => handleClick(e)}
      disabled={isDisabled}
      data-cy={id}
    >
      {children}
    </button>
  );
};

type ButtonVariantProps = Omit<ButtonProps, "variant">;

const Secondary = (props: ButtonVariantProps) => {
  return <Button variant="secondary" {...props} />;
};

const Tertiary = (props: ButtonVariantProps) => {
  return <Button variant="tertiary" {...props} />;
};

interface ISubmitButton extends ButtonProps {
  onClick: () => Promise<any>;
}
const Submit = (props: ISubmitButton) => {
  const [localDisabled, setLocalDisabled] = useState(false);
  const disabled = localDisabled || props.disabled || false;

  const handleClick = async () => {
    setLocalDisabled(true);
    props.onClick && (await props.onClick());
    setLocalDisabled(false);
  };

  return (
    <Button
      {...props}
      variant="primary"
      onClick={handleClick}
      disabled={disabled}
    />
  );
};

Button.Secondary = Secondary;
Button.Tertiary = Tertiary;
Button.Submit = Submit;

export default Button;
