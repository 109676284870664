import { ChangeEvent, KeyboardEvent } from "react";

import ctl from "@netlify/classnames-template-literals";

interface IInputField {
  label?: string;
  errorMessage?: string | null;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
  onBlur?: (val: string) => void;
  customClass?: string;
  onTab?: () => void;
}

const InputField = ({
  label,
  errorMessage,
  placeholder,
  value,
  onChange,
  onBlur,
  customClass,
  onTab,
}: IInputField) => {
  const inputCN = ctl(`
    typography-h300
    bg-n-000
    border border-solid border-n-700 
    active:border-b-400 focus:border-b-400 focus:outline-0
    rounded-[12px]
    px-[20px] py-[16px]
    w-[calc(100%-40px)]
    ${errorMessage && "border-r-300"}
    ${customClass}
  `);

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e?.key === "Tab" && onTab) onTab();
  };

  const _onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onBlur) return;
    e.stopPropagation();
    onBlur(e?.target?.value);
  };

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    e.stopPropagation();
    onChange(e?.target?.value);
  };

  return (
    <div className="w-full">
      {label && <p className="typography-h200 text-n-700">{label}</p>}
      <input
        className={inputCN}
        onChange={(e: ChangeEvent<HTMLInputElement>) => _onChange(e)}
        onBlur={(e: ChangeEvent<HTMLInputElement>) => _onBlur(e)}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
          onKeyPress(event)
        }
        value={value}
        placeholder={placeholder}
      />
      {errorMessage && (
        <p className="typography-h100 text-r-400 mt-[4px]">{errorMessage}</p>
      )}
    </div>
  );
};

export default InputField;
