const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const snakeToCamelCase = (str: string) =>
  str.replace(/_[A-Z]/g, (letter) => `${letter.toUpperCase()}`);

// @ts-ignore
const convertCase = (oldObject: any, caseConverter: any) => {
  let newObject: any;

  if (
    !oldObject ||
    typeof oldObject !== "object" ||
    !Object.keys(oldObject).length
  ) {
    return oldObject;
  }

  if (Array.isArray(oldObject)) {
    newObject = oldObject.map((element) => convertCase(element, caseConverter));
  } else {
    newObject = {};
    Object.keys(oldObject).forEach((oldKey) => {
      const newKey = caseConverter(oldKey);
      newObject[newKey] = convertCase(oldObject[oldKey], caseConverter);
    });
  }

  return newObject;
};

export const toSnakeCase = (obj: any) => convertCase(obj, camelToSnakeCase);
export const toCamelCase = (obj: any) => convertCase(obj, snakeToCamelCase);
