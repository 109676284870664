import { useState, useReducer, useEffect } from "react";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "../styles/phone-number.scss";

import InputField from "../design-system/InputField";
import SVGIcon from "../design-system/SVGIcon";
import InlineDialog, { useDialog } from "../design-system/InlineDialog";
import Textarea from "../design-system/Textarea";
import Checkbox from "../design-system/Checkbox";
import Button from "../design-system/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ToastMessage, { INotifData } from "../components/ToastMessage";
import CenterWrapper from "../design-system/CenterWrapper";

import ctl from "@netlify/classnames-template-literals";

import apiClient from "../hooks/apiClient";
import useKeyPress from "../hooks/useKeyPress";

interface IForm {
  firstname?: string;
  lastname?: string;
  jobTitle?: string;
  email?: string;
  phone?: string;
  companyName?: string;
  companySize?: string;
  message?: string;
}
const initialState = { selectedIndex: 0 };

const InlineDialogWrapper = ({ onChangeField }: any) => {
  const { open } = useDialog() || {};
  return (
    <InlineDialog.MenuItems
      customClass="w-[calc(100vw-156px)] lg:w-[37.5rem] px-0"
      vPosition="bottom"
      flexDirectionClass="flex-row"
      maxHeightWrapper={18}
    >
      {open && <InlineDialogContent onChangeField={onChangeField} />}
    </InlineDialog.MenuItems>
  );
};

const InlineDialogContent = ({ onChangeField }: any) => {
  const { setOpen, open } = useDialog() || {};

  const companySizeList = [
    "1-10",
    "11-50",
    "51-200",
    "201-500",
    "501-1000",
    "1000+",
    "Unknown",
  ];

  const reducer = (state: any, action: any) => {
    if (!companySizeList) return;
    switch (action.type) {
      case "arrowUp":
        return {
          selectedIndex:
            state.selectedIndex !== 0
              ? state.selectedIndex - 1
              : companySizeList.length - 1,
        };
      case "arrowDown":
        return {
          selectedIndex:
            state.selectedIndex !== companySizeList.length - 1
              ? state.selectedIndex + 1
              : 0,
        };
      case "reset":
        return {
          selectedIndex: 0,
        };
      default:
        throw new Error();
    }
  };

  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (arrowUpPressed && open) {
      dispatch({ type: "arrowUp" });
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed && open) {
      dispatch({ type: "arrowDown" });
    }
  }, [arrowDownPressed]);

  useEffect(() => {
    if (enterPressed && open) {
      const selectedSize = companySizeList[state?.selectedIndex];
      onChangeField("companySize", selectedSize);
      setOpen && setOpen();
    }
  }, [enterPressed]);

  useEffect(() => {
    if (open) {
      dispatch({ type: "reset" });
    }
  }, [open]);

  return (
    <>
      {companySizeList?.map((size, index) => (
        <InlineDialog.MenuItem
          onClick={() => onChangeField("companySize", size)}
          customClass="[&:not(:last-child)]:mb-[10px] w-full px-[20px]"
          key={index}
          index={index}
          selected={state?.selectedIndex === index}
        >
          <p className="typography-h300 text-n-900">
            {size} {size !== "Unknown" && " Employee"}
          </p>
        </InlineDialog.MenuItem>
      ))}
    </>
  );
};

const FormGIT = () => {
  const [data, setData] = useState<IForm>({});
  const [agree, setAgree] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<IForm>({});
  const [notifData, setNotifData] = useState<INotifData>({});

  const companySizeCN = ctl(`
    border border-solid border-n-700
    px-[20px] py-[16px] rounded-[12px]
    typography-h300
    w-[calc(100vw-160px)] lg:w-full
    flex justify-between
    ${data?.companySize ? "text-n-700" : "text-n-300"}
  `);

  const isWringFormatEmail = (email: string) => {
    const Regex =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !Regex.test(email);
  };

  const isWrongFormatEmail = isWringFormatEmail(data.email || "");

  const checkAbleToSubmit = () => {
    const isAllFiled = Object.values(errorMessage).every((x) => x === null);
    return agree && isAllFiled && !isWrongFormatEmail;
  };

  const onChangeField = (key: string, val: string) => {
    setData({ ...data, [key]: val });
    key !== "message" &&
      setErrorMessage({
        ...errorMessage,
        [key]: generateErrorMessage(key, val),
      });
    if (
      key === "companySize" &&
      (data?.message === null || data?.message === "" || !data?.message)
    ) {
      document.getElementById("textarea-message")?.focus();
    }
  };

  const generateErrorMessage = (key: string, val: string) => {
    //@ts-ignore
    if (val === "") return "This field is require";
    if (key === "email" && isWrongFormatEmail) {
      return "Oops..wrong format, please check again";
    }
    return null;
  };

  const submit = async () => {
    setIsLoading(true);
    const { isSuccess } = await apiClient("api/v1/contact_us", "POST", data);
    if (isSuccess) {
      setData({});
      setAgree(false);
    }
    setIsLoading(false);
    setNotifData({
      warning: !isSuccess,
      message: isSuccess
        ? "Your message is on its way! We appreciate you reaching out and will get back to you shortly."
        : `Uh oh! There seems to be a problem with your message. Don't worry, our team is on it, and we'll get things working smoothly ASAP.`,
    });
    setTimeout(() => {
      setNotifData({ warning: false, message: null });
    }, 3000);
  };

  return (
    <div className="bg-n-000 rounded-[20px] p-[40px] flex flex-col gap-[10px] mt-[40px] max-w-[37.25rem]">
      {notifData?.message && (
        <ToastMessage
          warning={notifData?.warning || false}
          message={notifData?.message}
          showNotif={true}
        />
      )}

      <div className="flex flex-col lg:flex-row gap-[16px]">
        <InputField
          value={data?.firstname || ""}
          onChange={(val) => onChangeField("firstname", val)}
          onBlur={(val) => onChangeField("firstname", val)}
          placeholder="First Name*"
          customClass="h-[22px]"
          errorMessage={errorMessage?.firstname}
        />
        <InputField
          value={data?.lastname || ""}
          onChange={(val) => onChangeField("lastname", val)}
          onBlur={(val) => onChangeField("lastname", val)}
          placeholder="Last Name*"
          customClass="h-[22px]"
          errorMessage={errorMessage?.lastname}
        />
      </div>
      <InputField
        value={data?.jobTitle || ""}
        onChange={(val) => onChangeField("jobTitle", val)}
        onBlur={(val) => onChangeField("jobTitle", val)}
        placeholder="Job Title*"
        customClass="h-[22px]"
        errorMessage={errorMessage?.jobTitle}
      />
      <InputField
        value={data?.email || ""}
        onChange={(val) => onChangeField("email", val)}
        onBlur={(val) => onChangeField("email", val)}
        placeholder="Work Email Address*"
        customClass="h-[22px]"
        errorMessage={errorMessage?.email}
      />
      <div>
        <PhoneInput
          defaultCountry={navigator.language}
          value={data?.phone}
          onChange={(phone) => {
            onChangeField("phone", phone);
          }}
        />
        {errorMessage?.phone && (
          <p className="typography-h100 text-r-400 mt-[4px]">
            {errorMessage?.phone}
          </p>
        )}
      </div>

      <InputField
        value={data?.companyName || ""}
        onChange={(val) => onChangeField("companyName", val)}
        onBlur={(val) => onChangeField("companyName", val)}
        onTab={() => {
          document.getElementById("dropdown-company-size")?.click();
        }}
        placeholder="Company Name*"
        customClass="h-[22px]"
        errorMessage={errorMessage?.companyName}
      />
      <div className="w-full">
        <InlineDialog customClass="w-full">
          <InlineDialog.Trigger>
            <div className={companySizeCN} id="dropdown-company-size">
              {data?.companySize || "Company Size*"}{" "}
              {!!data?.companySize &&
                data?.companySize !== "Unknown" &&
                " Employee"}
              <SVGIcon
                iconName="icon-arrow-down"
                size={20}
                fillColor="var(--n-700)"
              />
            </div>
          </InlineDialog.Trigger>
          <InlineDialogWrapper onChangeField={onChangeField} />
        </InlineDialog>

        {errorMessage?.companySize && (
          <p className="typography-h100 text-r-400 mt-[4px]">
            {errorMessage?.companySize}
          </p>
        )}
      </div>

      <Textarea
        value={data?.message || ""}
        onChange={(message) => onChangeField("message", message)}
        onBlur={(message) => onChangeField("message", message)}
        placeholder="Type your Message here"
        customClass="w-[calc(100vw-160px)] lg:w-[calc(100%-2.9rem)] h-[144px]"
        errorMessage={errorMessage?.message}
        id="textarea-message"
      />
      <div className="flex items-start gap-[8px]">
        <Checkbox
          id="agreement"
          value="agreement"
          onChange={() => setAgree(!agree)}
          checked={!!agree}
        />
        <p className="typography-h200 text-n-300 w-[44.5rem]">
          By submitting my information, I confirm that I have read and
          understood the Provenant Cloud Privacy Policy (embedded link to
          privacy policy page)
        </p>
      </div>
      <Button
        id="Submit"
        customClass="w-full h-[62px]"
        disabled={!checkAbleToSubmit() || isLoading}
        onClick={submit}
      >
        Send Message
      </Button>
    </div>
  );
};

const GetInTouch = () => {
  const contactDetails = [
    {
      iconName: "icon-mail",
      title: "E-mail",
      desc: "info@provenant.nl",
    },
    {
      iconName: "icon-support",
      title: "Support",
      desc: "support@provenant.nl",
    },
    {
      iconName: "icon-phone",
      desc: "+316 21851613",
    },
    {
      iconName: "icon-home-phone",
      desc: "+31 413 483101",
    },
    {
      iconName: "icon-address",
      desc: "Jane Addamsstraat 4, 5491 DE Sint-Oedenrode, Netherlands",
    },
  ];

  const companyDetails = [
    {
      title: "Chamber of Commerce No.",
      desc: "93077726",
    },
    {
      title: "Tax ID",
      desc: "NL866265430B01",
    },
  ];

  return (
    <div className="bg-n-100">
      <Header />

      <CenterWrapper backgroundColor="grey">
        <div className="flex flex-col lg:flex-row justify-between pt-[36px] pl-[20px] pr-[20px] pb-[36px] lg:pt-[56px] lg:pl-[112px] lg:pr-[62px] lg:pb-[156px]">
          {/* left content */}
          <div className="flex flex-col gap-[48px] w-[calc(100vw-40px)] lg:w-[26.25rem]">
            <div className="flex flex-col gap-[8px]">
              <p className="text-n-700 typography-h400-bold">Get In Touch</p>
              <p className="text-n-700 typography-h600-bold">Let’s Talk More</p>
              <p className="text-n-300 typography-h300">
                Request a quote, demo or more information about our service and
                what we can do to boost your business. Contact us directly, or
                fill out this form. Rest assured that we will promptly address
                your inquiry and provide the necessary assistance at the
                earliest convenience
              </p>
            </div>

            <div className="bg-n-000 rounded-[12px] p-[20px] w-[calc(100vw-80px)] lg:w-[25.75rem] flex flex-col gap-[20px]">
              <p className="text-n-700 typography-h400-bold">Contact Details</p>
              <div className="flex flex-col gap-[20px]">
                {contactDetails?.map((detail) => (
                  <div className="flex gap-[20px]">
                    <SVGIcon
                      iconName={detail?.iconName}
                      size={24}
                      fillColor="var(--n-300)"
                    />
                    <div className="flex flex-col gap-[4px]">
                      {detail?.title && (
                        <p className="typography-h300-bold text-n-700">
                          {detail?.title}
                        </p>
                      )}
                      <p className="typography-h300 text-n-700">
                        {detail?.desc}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="w-full h-[1px] bg-n-300" />
                {companyDetails?.map((detail) => (
                  <div className="flex flex-col gap-[8px]">
                    <p className="typography-h300-bold text-n-700">
                      {detail?.title}
                    </p>
                    <p className="typography-h300 text-n-700">{detail?.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* right content */}
          <FormGIT />
        </div>
      </CenterWrapper>
      <Footer />
    </div>
  );
};

export default GetInTouch;
