import { useEffect, useState } from "react";

import ctl from "@netlify/classnames-template-literals";

import SVGIcon from "../design-system/SVGIcon";

interface IToastMessage {
  warning: boolean;
  showNotif: boolean;
  message: string;
}

const ToastMessage = ({ warning, showNotif, message }: IToastMessage) => {
  const [firstRender, setFirstRender] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const toastCN = ctl(`
    fixed top-[124px] left-[40%] inline-flex
    ${!isVisible ? "invisible " : ""}
    p-[10px] border border-solid z-[10]
    ${warning ? "bg-r-400 border-r-600" : "bg-g-400 border-g-600"}
    ${showNotif ? "fade-in" : "fade-out"}
    text-n-000 rounded-[8px]
  `);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    setTimeout(
      () => {
        setIsVisible(showNotif);
      },
      showNotif ? 0 : 300
    );
  }, [showNotif, firstRender]);

  return (
    <div className={toastCN} style={{ transform: "translate(-30%, -50%)" }}>
      <SVGIcon
        iconName={warning ? "icon-cross" : "icon-check"}
        size={24}
        fillColor="var(--n-000)"
        customClass="mr-[10px]"
      />
      <p className="typography-h300 whitespace-pre-line text-n-000 text-center">
        {message}
      </p>
    </div>
  );
};

export default ToastMessage;

export interface INotifData {
  warning?: boolean;
  message?: string | null;
}
