import { useHistory } from "react-router";
import CenterWrapper from "../design-system/CenterWrapper";

const GenerateLeftFooter = () => {
  const history = useHistory();

  const directions = [
    {
      title: "General Terms and Conditions",
      redirectUrl: "/en/general-terms-and-conditions",
    },
    {
      title: "Privacy Policy",
      redirectUrl: "/en/privacy-statement",
    },
    {
      title: "Master Services Agreement",
      redirectUrl: "/en/service-agreement",
    },
    {
      title: "Data Processing Agreement",
      redirectUrl: "/en/processor-agreement",
    },
  ];

  return (
    <div className="flex flex-col gap-[20px]">
      <img
        src="/assets/svg/provenant_logo.svg"
        className="height-auto w-[156px]"
        alt="provenant logo"
      />
      <div className="flex flex-col gap-[8px]">
        {directions?.map((dir) => (
          <p
            className="typography-h200 text-n-700 hover:text-b-400 cursor-pointer"
            onClick={() =>
              dir?.redirectUrl && history.replace(dir?.redirectUrl)
            }
          >
            {dir?.title}
          </p>
        ))}
      </div>
      <img
        src="/assets/svg/badge_iso.svg"
        className="height-auto w-[268px]"
        alt="provenant logo"
      />
    </div>
  );
};

const GenerateRightFooter = () => {
  const history = useHistory();

  const menus = [
    {
      title: "Product",
      sub: [
        {
          title: "Integrated Collaborative Workflow",
          redirectUrl: "/integrated-workflow",
        },
        {
          title: "Asset Management",
          redirectUrl: "/asset-management",
        },
        {
          title: "Invoicing",
          redirectUrl: "/invoicing",
        },
        {
          title: "Supply Chain Visibility and Analytics",
          redirectUrl: "/supply-chain",
        },
        {
          title: "Exception Management",
          redirectUrl: "/exception-management",
        },
        {
          title: "Manufacturing Collaboration",
          redirectUrl: "/manufacturing-execution",
        },
        {
          title: "Shipping Management",
          redirectUrl: "/shipping-management",
        },
        {
          title: "Quotation and Order Collaboration",
          redirectUrl: "/order-collaboration",
        },
      ],
    },
    {
      title: "Your Industry",
      sub: [
        {
          title: "Wholesale/Distributor",
          redirectUrl: "/wholesale",
        },
        {
          title: "Manufacturing",
          redirectUrl: "/manufacturing",
        },
      ],
    },
    {
      title: "Services",
      sub: [
        {
          title: "Software Implementation",
          redirectUrl: "/software-implementation",
        },
        {
          title: "Custom Software Development",
          redirectUrl: "/custom-software",
        },
        {
          title: "Software Development Outsourcing",
          redirectUrl: "/outsourcing",
        },
        {
          title: "Managed IT Services",
          redirectUrl: "/it-service",
        },
      ],
    },
    {
      title: "Company",
      sub: [
        {
          title: "About us",
          redirectUrl: "/about-us",
        },
        {
          title: "Contact us",
          redirectUrl: "/getintouch",
        },
        {
          title: "Vacancies",
          redirectUrl: "/vacancies",
        },
        {
          title: "Partner",
          redirectUrl: "/partner",
        },
      ],
    },
  ];
  return (
    <div className="flex flex-col lg:flex-row gap-[28px] mt-[44px] lg:mt-0">
      {menus?.map((menu) => (
        <div className="flex flex-col gap-[20px]">
          <p className="typography-h300-bold text-n-700">{menu?.title}</p>
          {menu?.sub?.map((subMenu) => (
            <p
              className="typography-h200 text-n-700 cursor-pointer hover:text-b-400"
              onClick={() =>
                subMenu?.redirectUrl && history.replace(subMenu?.redirectUrl)
              }
            >
              {subMenu?.title}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

const Footer = () => {
  return (
    <CenterWrapper>
      <div className="w-full bg-n-000 py-[56px] pl-[20px] pr-[20px] lg:py-[32px] lg:pl-[100px] lg:pr-[88px] flex flex-col lg:flex-row justify-between box-border">
        <GenerateLeftFooter />
        <GenerateRightFooter />
      </div>
    </CenterWrapper>
  );
};

export default Footer;
