import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Table from "../../design-system/Table";
import CenterWrapper from "../../design-system/CenterWrapper";
import BreadCrumbs from "../../design-system/Breadcrumbs";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";

const Subprocessor = () => {
  const { isShowBreadcrumbs, parentTitle, url } = useBreadcrumbs();

  const subProcessorList = {
    effectiveDate: "1 April 2024",
  };

  const headers = [
    "Subprocessor",
    "Address",
    "Service",
    "Data Location",
    "Data Transfer Mechanism",
  ];

  const contents = [
    {
      answer: [
        "Azure",
        "Redmond, 1 Microsoft Way, United States",
        "Hosting",
        "Western Europe",
        "Standard Contractual Clauses",
      ],
    },
    {
      answer: [
        "Azure",
        "Redmond, 1 Microsoft Way, United States",
        "Hosting",
        "Northern Europe",
        "Standard Contractual Clauses",
      ],
    },
  ];
  return (
    <>
      <Header />
      <CenterWrapper backgroundColor="grey">
        <div className="py-[24px] px-[20px] lg:py-[76px] lg:px-[84px]">
          {isShowBreadcrumbs && parentTitle && (
            <BreadCrumbs>
              <BreadCrumbs.Item value={parentTitle} url={url} />
              <BreadCrumbs.Item value="Subprocessors List" />
            </BreadCrumbs>
          )}
          <p className="typography-h600-bold text-n-900">Subprocessors List</p>
          <p className="typography-h400-bold text-n-900 mt-[16px] lg:mt-[40px] mb-[12px]">
            Effective Date: {subProcessorList?.effectiveDate}
          </p>
          <Table headers={headers} contents={contents} />
        </div>
      </CenterWrapper>
      <Footer />
    </>
  );
};

export default Subprocessor;
