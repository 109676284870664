import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ServiceTemplate from "../../components/ServiceTemplate";

const SoftwareImplementation = () => {
  const introData = {
    icon: "/assets/svg/home/fulfillment.gif",
    title: "Software Implementation",
    topDesc: "Don't Settle for Subpar Software Implementation Experiences",
    bottomDesc:
      "Experience the difference firsthand. Say hello to efficiency, productivity, and success like never before!",
    illustration: "/assets/svg/product/asset-management-illus.svg",
  };

  const serviceDetails = [
    {
      image: "/assets/svg/service/tailord-solutions.svg",
      desc: "We understand that every business is unique, which is why we offer customized solutions tailored to your specific needs. Whether you're a small startup or a multinational corporation, Our service adapts to fit your requirements, ensuring a personalized experience every time.",
      name: "Tailored Solutions",
    },
    {
      image: "/assets/svg/service/speed-efficiency.svg",
      desc: "Time is money, and with our service, you'll save plenty of both! Our streamlined approach to software implementation means faster deployment times, allowing you to start reaping the benefits of your new software sooner rather than later.",
      name: "Speed and Efficiency",
    },
    {
      image: "/assets/svg/service/seamless-integration.svg",
      desc: "Our app seamlessly integrates with your existing systems, ensuring a smooth transition with minimal disruptions to your workflow. Say goodbye to compatibility headaches and hello to effortless integration!",
      name: "Seamless Integration",
    },
  ];

  return (
    <>
      <Header />
      <ServiceTemplate introData={introData} serviceDetails={serviceDetails} />
      <Footer />
    </>
  );
};

export default SoftwareImplementation;
