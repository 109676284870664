import CenterWrapper from "../design-system/CenterWrapper";

const Partner = () => {
  const listImage = [
    "/assets/svg/home/company-1.png",
    "/assets/svg/home/company-2.png",
    "/assets/svg/home/company-4.png",
    "/assets/svg/home/company-5.jpg",
    "/assets/svg/home/company-6.png",
    "/assets/svg/home/company-7.png",
    "/assets/svg/home/company-8.png",
    "/assets/svg/home/company-3.png",
    "/assets/svg/home/company-10.png",
    "/assets/svg/home/company-9.svg",
    "/assets/svg/home/company-11.webp",
    "/assets/svg/home/company-12-lempens.png",
  ];
  return (
    <CenterWrapper>
      <div className="flex items-center w-full overflow-hidden whitespace-nowrap h-[84px] lg:h-[268px] bg-n-000 mb-[12px]">
        <div className="inline-block infinite-scroll-partner">
          {listImage?.map((imgUrl) => (
            <img
              src={imgUrl}
              className="h-[36px] lg:h-[88px] mx-[20px]"
              alt="Partner"
              loading="lazy"
            />
          ))}
        </div>
        <div className="inline-block infinite-scroll-partner">
          {listImage?.map((imgUrl) => (
            <img
              src={imgUrl}
              className="h-[36px] lg:h-[88px] mx-[20px]"
              alt="Partner"
              loading="lazy"
            />
          ))}
        </div>
      </div>
    </CenterWrapper>
  );
};

export default Partner;
