import { useHistory } from "react-router";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../design-system/Button";
import PlanCard from "./PlanCard";
import FaqItem from "./FaqItem";
import CenterWrapper from "../../design-system/CenterWrapper";

interface IGrouping {
  title: string;
  plans: string[];
}

interface IPackage {
  title: string;
  desc: string;
  price: string;
  grouping: IGrouping[];
}
const Plans = () => {
  const history = useHistory();

  const packages = [
    {
      title: "Starter Package",
      desc: "Get started with live tracking and seamless collaboration",
      price: "Let’s Talk",
      grouping: [
        {
          title: "Order Collaboration",
          plans: [
            "Order Management",
            "Order Fulfilment",
            "Invoice Management",
            "3 Live Boards",
            "Unlimited Users",
            "Requested Order Completion Date",
          ],
        },
        {
          title: "Exception Management",
          plans: [
            "Lifecycle from Order to Invoicing with 5 Milestones",
            "Daily Updates",
            "Comprehensive Search and Filter Capabilities",
            "2 Custom Data Fields",
          ],
        },
        {
          title: "Analytics & Planning",
          plans: [
            "3 Months of Historical Data",
            "Invoice Reports",
            "Order Reports",
          ],
        },
        {
          title: "Digital Document Management",
          plans: [
            "Invoices, Order Confirmations",
            "Create PDF Documents",
            "Live Chat Support",
          ],
        },
      ],
    },
    {
      title: "Business",
      desc: "More power for your teams. Includes everything in Starter, plus…",
      price: "Let’s Talk",
      grouping: [
        {
          title: "Order Collaboration",
          plans: [
            "Purchasing and Quotation Management",
            "Shipment Management",
            "Product and Raw Materials Management",
            "Bill of Material Support",
            "Order Processing Instructions",
            "Order Priority Setting",
          ],
        },
        {
          title: "Digital Document Management",
          plans: [
            "Invoices, Order Confirmations",
            "Email Invoices and Other Document",
          ],
        },
        {
          title: "Analytics & Planning",
          plans: [
            "12 Months of Historical Data",
            "Carbon Remissions Reporting",
            "Workflow with Manufacturing",
            "Export Data to CSV (/Excel) ​",
          ],
        },
        {
          title: "Tracking",
          plans: [
            "Lifecycle from Purchasing to Shipping with 10 Milestones ​",
            "Updates Every 4 Hours​",
            "Order Fulfilment ETA​",
            "10 Custom Data Fields, 10 Labels​",
            "Traceability​",
          ],
        },
        {
          title: "ESG Compliance and Reporting",
          plans: [
            "Survey Suppliers​",
            "ESG Data​",
            "Generate ESG Compliance Report​",
          ],
        },
        {
          title: "Support",
          plans: ["Dedicated Customer Success Manager​"],
        },
      ],
    },
    {
      title: "Enterprise Lite",
      desc: "More power for your teams. Includes everything in Business, plus…",
      price: "Let’s Talk",
      grouping: [
        {
          title: "Order Collaboration",
          plans: [
            "Accounts Receivable and (Online) Payments",
            "Unlimited Live Boards​",
            "Single Sign-On (SSO)​",
          ],
        },
        {
          title: "Digital Document Management",
          plans: [
            "Invoices, Order Confirmations",
            "Create PDF Documents",
            "Live Chat Support",
          ],
        },
        {
          title: "Analytics & Planning",
          plans: [
            "Unlimited Historical Data​",
            "Workflow with Manufacturing​",
            "Custom Reports",
          ],
        },
        {
          title: "Tracking",
          plans: [
            "Entire Order-To-Cash Lifecycle with 20 Milestones",
            "Live Order Fulfilment Status",
            "Hourly Updates​",
            "Incoming Goods Visibility​",
            "Unlimited Fields and Labels​",
          ],
        },
        {
          title: "Connectivity",
          plans: [
            "Connectors with ERP (incl. SAP, Oracle etc.)​",
            "Integrations wit​",
            "Risk Management​",
          ],
        },
        {
          title: "ESG Compliance and Reporting",
          plans: ["Survey Suppliers​", "ESG Data​", "Risk Management​"],
        },
        {
          title: "Support",
          plans: [
            "Support Cccording to 8/5 SLA, 24/5 or 24/7.​",
            "Minimum Uptime of 95%.​",
          ],
        },
      ],
    },
  ];

  const features = [
    {
      icon: "/assets/svg/plans/project-feature.gif",
      title: "Project Management",
      desc: "Your secret weapon for conquering projects.  You'll impress your clients, keep your team happy, and finally achieve that work-life balance you've always dreamed of.  So ditch the stress and download our app today!  We're offering a free trial, so you can experience the magic for yourself.",
    },
    {
      icon: "/assets/svg/plans/asset-feature.gif",
      title: "Asset Management",
      desc: "Empowers you to extract maximum value from your assets, minimize risks, and drive operational excellence. It's not just about keeping track of your stuff, it's about unlocking the full potential of your resources to fuel growth and success",
    },
  ];

  const faqList = [
    {
      question: "Do you provide ongoing support for the SaaS application?",
      answer:
        "Yes, we offer various support packages including phone, email, and chat support. We can also provide additional training as needed",
    },
    {
      question: "What happens to my data if I stop using your service?",
      answer:
        "Your data remains yours. We offer secure data export options upon request.",
    },
    {
      question: "Do you offer training for our team on this application?",
      answer:
        "Absolutely! We provide comprehensive user training during implementation, covering core functionalities, workflows, and best practices. Training materials and ongoing support resources will also be available.",
    },
    {
      question: "Can we customize the application during implementation?",
      answer:
        "Yes, to a certain extent. We offer configuration options to tailor the interface, user roles, and workflows to your specific needs. For extensive customization, we can discuss additional development options",
    },
    {
      question: "Do you offer any data migration assistance?",
      answer:
        "Yes, we can assist with data migration from your existing systems to the new SaaS application. We'll work with you to ensure data security and integrity throughout the process.",
    },
    {
      question: "How do we submit support requests?",
      answer:
        "We offer a dedicated online portal for submitting and tracking support tickets. You can also reach out to our support team directly via phone or email.",
    },
  ];

  return (
    <div className="bg-n-100">
      <Header />
      {/* intro */}
      <CenterWrapper backgroundColor="grey">
        <div className="flex flex-col gap-[8px] pt-[44px] pl-[20px] pb-[32px] lg:pt-[76px] lg:pl-[112px] lg:pb-[56px]">
          <p className="w-[calc(100vw-40px)] lg:w-[568px] typography-h700-bold text-n-700">
            Your Business Scalable Growth Starts Here
          </p>
          <p className="typography-h340 text-n-700 my-[8px]">
            Try out for 14 days, free. Then choose the plan that suits you best.
          </p>
          <Button
            id="talktosales"
            onClick={() => history.replace("/getintouch")}
            customClass="px-[36px]"
          >
            Talk to Sales
          </Button>
        </div>
      </CenterWrapper>

      {/* package */}
      <CenterWrapper backgroundColor="grey">
        <div className="mt-0 pl-[20px] pr-[20px] pb-[28px] flex flex-col lg:flex-row gap-[28px] lg:gap-[68px] lg:mt-[4.5rem] lg:pl-[100px] lg:pr-[76px] lg:pb-[56px]">
          {packages?.map((packageItem: IPackage) => (
            <div>
              <div className="flex flex-col gap-[8px] mb-[16px] lg: mb-[28px] w-[calc(100vw-40px)] lg:w-[356px]">
                <p className="typography-h600-bold text-n-700">
                  {packageItem.title}
                </p>
                <p className="typography-h300 text-n-700">
                  {packageItem?.desc}
                </p>
                <p className="typography-h600-bold text-n-700">
                  {packageItem?.price}
                </p>
              </div>
              <div className="flex flex-col gap-[16px]">
                {packageItem?.grouping?.map((group) => (
                  <PlanCard group={group} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </CenterWrapper>

      {/* add on */}
      <CenterWrapper>
        <div className="bg-n-000 pt-[32px] pl-[20px] pb-[28px] lg:pt-[28px] pr-[20px] lg:pl-[88px] lg:pb-[46px] lg:pr-0">
          <p className="typography-h600-bold text-n-700 mb-[46px]">
            Add On Features
          </p>

          <div className="flex flex-col lg:flex-row gap-[40px] mb-[56px]">
            {features?.map((feature) => (
              <div className="flex flex-col gap-[12px] max-w-[456px]">
                <img
                  src={feature.icon}
                  className="h-[80px] w-[80px]"
                  alt={feature.title}
                />
                <p className="typography-h340-bold text-n-700">
                  {feature.title}
                </p>
                <p className="typography-h300 text-n-300">{feature.desc}</p>
              </div>
            ))}
          </div>
          <Button
            id="talktosales"
            onClick={() => history.replace("/getintouch")}
            customClass="px-[36px]"
          >
            Talk to Sales
          </Button>
        </div>
      </CenterWrapper>

      {/* faq */}
      <CenterWrapper backgroundColor="grey">
        <div className="pl-[20px] lg:pl-[100px] pt-[40px] lg:pt-0 pr-[20px] lg:pr-0 flex flex-col lg:flex-row justify-between relative">
          <img
            src="/assets/svg/plans/faq-illus.svg"
            alt="faq illustration"
            className="unset lg:absolute right-0 mt-0 mx-auto lg:mx-0 lg:mt-[-112px] w-[232px] lg:w-auto"
          />
          <div className="my-[4.5rem] lg:my-[40px]">
            <p className="typography-h500-bold text-n-700">
              Frequent Asked Questions
            </p>
            <p className="typography-h600-bold text-n-700 mt-[4px]">
              Haven’t Found What you Looking for
            </p>

            <div className="flex flex-col gap-[20px] mt-[20px] lg:mt-[48px]">
              {faqList?.map((faq, index) => (
                <FaqItem faq={faq} key={index} index={index} />
              ))}
            </div>
          </div>
        </div>
      </CenterWrapper>

      <Footer />
    </div>
  );
};

export default Plans;
