import Header from "../components/Header";
import Footer from "../components/Footer";
import Partner from "../components/Partner";
import CenterWrapper from "../design-system/CenterWrapper";

const AboutUs = () => {
  return (
    <div className="overflow-x-hidden bg-n-100">
      <Header />

      {/* intro */}
      <CenterWrapper backgroundColor="grey">
        <div className="flex justify-between pl-0 lg:pl-[100px] flex-col-reverse lg:flex-row">
          <div className="flex flex-col max-w-[40rem] gap-[24px] mt-[24px] lg:mt-[182px] px-[20px] lg:px-0">
            <p className="typography-h500-bold text-n-700">About us</p>
            <p className="typography-h600-bold text-n-700">
              Ensuring memorable experiences throughout every touchpoint
            </p>
          </div>
          <img src="/assets/svg/about-us.png" alt="about us" />
        </div>
      </CenterWrapper>

      {/* desc */}
      <CenterWrapper backgroundColor="grey">
        <div className="py-[32px] lg:py-[56px] pr-[20px] lg:pr-[32px] pl-[20px] lg:pl-[100px] flex flex-col lg:flex-row justify-between">
          <p className="typography-h500-bold text-n-700 max-w-[11.625rem]">
            Welcome to Provenant
          </p>
          <div className="w-[56.25rem] flex flex-col gap-[24px] mb-[8px]">
            <p className="typography-h340 text-n-300">
              The forefront of innovation in supply chain visibility
              and collaboration. We are a team driven by the mission to blend
              the authenticity of provenance with the efficiency of automation,
              ensuring every product's journey from creation to customer
              is transparent, accountable, and seamlessly managed.​
            </p>
            <p className="typography-h340 text-n-300">
              In a rapidly evolving marketplace, Provenant is not just
              responding to change – we are creating it. Our platform offers
              (real-time) manufacturing visibility, intelligent order
              management, unparalleled product traceability and
              automated supplier collaboration, setting new standards in the
              industry.
            </p>
            <p className="typography-h340 text-n-300">
              We also provide our partners with A platform with ERP and SCM
              capabilities that are offered under an enterprise-lite framework.
              This platform needs to be SMB ‘friendly’ and modularised
            </p>
          </div>
        </div>
      </CenterWrapper>

      <Partner />

      <Footer />
    </div>
  );
};

export default AboutUs;
