import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Table from "../../design-system/Table";
import BreadCrumbs from "../../design-system/Breadcrumbs";
import CenterWrapper from "../../design-system/CenterWrapper";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";

interface IContent {
  answer: string[];
}
interface ITable {
  headers: string[];
  contents: IContent[];
  type: string;
}

const GenerateAnswer = ({ answer }: { answer: string | ITable }) => {
  if (typeof answer !== "string" && answer?.type == "table") {
    const { headers, contents } = answer;
    return <Table headers={headers} contents={contents} />;
  }

  if (typeof answer == "string") {
    return (
      <p
        className="typography-h340 text-n-300 mb-[8px] whitespace-pre-wrap"
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    );
  }

  return <></>;
};

const PrivacyPolicy = () => {
  const { isShowBreadcrumbs, parentTitle, url } = useBreadcrumbs();

  const privacyData = {
    effectiveDate: "25 April 2022",
    intro: [
      "Welcome to the Privacy Policy of Provenant Cloud, a label of Provenant B.V. We are a Dutch company that develops systems and services to assist enterprises in easily collaborating and having visibility across their supply chain.",
      `These privacy policies inform you about:
        •	What information we collect about you
        •	How we use this information
        •	Your rights and options regarding the information we collect`,
      "The term “information and/or Data about you” in this policy has the same meaning as “personal data” and refers to data that we can use to identify you or that is otherwise related to you. We have tried to make these privacy policies easy to read and understand for you. If you have any questions or need additional information about the processing of information about you, you can contact us as explained in Section 18 below.",
    ],
    questions: [
      {
        title: "What falls under these Privacy Policies",
        answers: [
          `These Privacy Policies apply to information about you that we collect when you:
          •	Visit our websites
          •	Purchase something from us or express interest in our services
          •	Use our products, services, or applications
          •	Communicate with our team about questions and incidents
          •	Apply to work with us`,
        ],
      },
      {
        title:
          "Who is responsible for the collection and use of information about you?",
        answers: [
          "Provenant B.V., located at Jane Addamsstraat 4, 5491DE in Sint-Oedenrode, The Netherlands, is the “Controller” when we collect and use information about you in accordance with these Privacy Policies. Provenant Cloud is a label of Provenant B.V.",
          "As the Controller for processing, we must ensure that the collection and use of information occur in a balanced and secure manner. We must also respect your rights, including your right to access the information we store about you. From time to time, we collaborate with other companies to collect and use information about you. Further information about these third parties can be found in Sections 10, 11, and 12.",
          `You can contact us for privacy inquiries and to exercise your rights as per Section 15 at <a href="mailto:privacy@provenant.nl">privacy@provenant.nl<a/>.`,
        ],
      },
      {
        title: "What types of information do we collect about you?",
        answers: [
          `The information we collect about you depends on your relationship with us and your interactions with us. If you are a customer or user of our products and services, we are likely to collect both information you provide to us and information that is automatically collected when you use our services. We refer to this data as “Service Data.”
            •	Account & Contact Information
            •	Contact Information: Your name, email address, physical address, phone number, or other contact information
            •	Account Information: Your account identifier (email address), account status, login credentials, and associated devices
            •	Purchase Information: Data about purchases of our products or services, as well as data collected in connection with the conclusion or performance of a contract
            •	Payment Information: Data about your payment methods, such as card details, and your billing address
            •	Communication Data: Your communication with us, including interactions with customer support and our social media channels
            •	Marketing: Information you may provide to us as a participant in events, campaigns, or as a beta tester.
            •	Application & Human Resources: Data needed for applying to work with us or for your employment, such as resume information, social security number and/or passport, gender, date of birth, physical address, and phone number.
            •	Technical Data
            •	Device Data: Data about the devices you use to access our services, including device type, browser version, and data that allows us to identify your device (such as a cookie)
            •	Event Data: Data about your use of our products and services, such as whether and how often you use product features, functions, or services. We also collect performance data, crash logs, and other diagnostic data.
            •	Other Technical Data: Data about the interaction of your apps, browsers, and devices with our services and systems, including IP address, login credentials, system activity
            •	Behavioral and Preference Data: Data about your behavior, such as how you navigate our website, the source website, whether you arrive at our website through our advertising or newsletters, and your communication and privacy settings.`,
        ],
      },
      {
        title: "What types of information do we collect about you?",
        answers: [
          `Information we occasionally collect receive from or collect from public sources when it is relevant to legal proceedings.`,
          `Additionally, we process personal data within the data you enter into our system (referred to as “Your Data”) when you use our products and services. For more information on how we process Your Data, please refer to Section 5.`,
        ],
      },
      {
        title: "How do we handle Your Data?",
        answers: [
          `In addition to the Service Data and technical data we collect about you, you may use our products and services to record work hours, expenses, kilometers, and customer information in the client portal, as well as invoice information. We refer to this as “Your Data.” You are solely responsible for Your Data. We store and transmit Your Data to provide our services to you. Legally, we act as a “data processor” when processing Your Data, and we do not use or have access to Your Data for other purposes unless you provide us with instructions or it is ordered by a court. When we act as a data processor, we are legally obligated to enter into a binding legal agreement with you that governs the processing of data on your behalf. This agreement is referred to as a “Data Processing Agreement” (DPA) and is available as an appendix to the Terms and Conditions.`,
          `You can delete Your Data at any time through our apps. If you delete your account for any reason, all data stored on our servers will also be deleted, but please note that content may be stored in our backup systems for up to 3 months.`,
        ],
      },
      {
        title: "Your Responsibility for Your Data?",
        answers: [
          `As you are solely responsible for your content, you must ensure compliance with all relevant laws that may apply to Your Data. We are not responsible for ensuring compliance with these laws.`,
          `Example: If Your Data contains personal information about other individuals (referred to as “personal data”), you must ensure that the collection, storage, and use of this information are legally permissible.`,
        ],
      },
      {
        title: "How do we use information about you?",
        answers: [
          `Depending on your relationship with us, we may process information about you for various purposes.`,
          {
            type: "table",
            headers: [
              "Purpose",
              "Processing Activities",
              "Categories of Information",
            ],
            contents: [
              {
                answer: [
                  "Providing Products and Services",
                  "Orders and payments, notifications, issue resolution",
                  "Contact information, account information, purchase information, event data, other technical data, Your Data (if necessary)",
                ],
              },
              {
                answer: [
                  "Providing Customer Support",
                  "Handling support tickets, using information for training purposes",
                  "Depending on the type of support: contact information, account information, purchase information, payment information, communication data, device data, event data, other technical data",
                ],
              },
              {
                answer: [
                  "Troubleshooting, Technical Issues, and Incidents",
                  "Troubleshooting, investigating reported issues",
                  "Contact information, device data, event data, other technical data",
                ],
              },
              {
                answer: [
                  "Fraud Prevention and Abuse Prevention",
                  "Fraud prevention, abuse prevention",
                  "Contact information, account information, purchase information, payment information, device data, event data, other technical data",
                ],
              },
              {
                answer: [
                  "Improving Products and Services",
                  "Usage analysis, surveys",
                  "Account information, purchase information, device data, event data, other technical data, behavioral and preference data",
                ],
              },
              {
                answer: [
                  "Promoting Products and Services",
                  "Newsletters, marketing emails, online interaction",
                  "Contact information, account information, purchase information, other information, device data, event data, other technical data, behavioral and preference data",
                ],
              },
              {
                answer: [
                  "Compliance with Laws and Legal Requirements",
                  "Accounting, security, disclosure",
                  "Depending on legal requirements: contact information, account information, purchase information, payment information, device data, event data, other technical data, Your Data",
                ],
              },
              {
                answer: [
                  "Human Resources",
                  "Applications, onboarding, offboarding",
                  "CV information, social security number and/or passport, gender, date of birth, physical address, and phone number",
                ],
              },
              {
                answer: [
                  "Risk Management and Strategic Planning",
                  "Performance monitoring, legal protection",
                  "Contact information, account information, purchase information, payment information, device data, event data, other technical data, information from public sources (if relevant)",
                ],
              },
            ],
          },
          "We do not use algorithms or profiling to make decisions that would significantly affect you without the possibility of human review. We also do not use sensitive personal data for other purposes.",
        ],
      },
      {
        title:
          "What legal basis do we have for processing information about you?",
        answers: [
          `The legal basis for the collection and use of your data depends on the nature of your relationship with us, the types of information we collect and use, and the purposes for which we use your information.`,
          {
            type: "table",
            headers: ["Purpose", "Legal Basis"],
            contents: [
              {
                answer: [
                  "Providing Products and Services & Issue Resolution",
                  "Contract Performance",
                ],
              },
              {
                answer: [
                  "Providing Customer Support & Staff Training",
                  "Contract Performance",
                ],
              },
              {
                answer: [
                  "Fraud Prevention and Abuse Prevention",
                  "Legitimate Interest",
                ],
              },
              {
                answer: [
                  "Improving Products and Services",
                  "Legitimate Interest",
                ],
              },
              {
                answer: ["Direct Marketing", "Legitimate Interest"],
              },
              {
                answer: [
                  "Accounting and Legal Requirements",
                  "Legitimate Interest",
                ],
              },
              {
                answer: ["Human Resources", "Legal Obligations"],
              },
              {
                answer: [
                  "Risk Management and Business Development",
                  "Legal Obligations",
                ],
              },
              {
                answer: [
                  "Other Purposes (with Consent)",
                  "Consent (if provided)",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "How long do we retain information about you?",
        answers: [
          `The duration for which we retain information about you depends on various factors, but in general, we aim to keep information about you only for as long as is necessary to fulfill the purposes described in Section 7. Other relevant factors in determining how long we need to retain information about you include:
            •	Applicable legal or regulatory requirements for retaining information (e.g., for accounting or security reasons)
            •	The nature of your relationship with us (e.g., how long you were a customer and whether you used our products and services)
            •	The categories of information we store about you and the nature of that information
            •	Potential legal disputes or investigations in which the information could be relevant`,
          `Generally, we retain information about you during the time you are an active customer and for 7 years after you are no longer an active customer. However, we may retain your personal information for longer if necessary to comply with legal obligations, resolve disputes, and enforce our rights. After the relevant retention period expires, we will securely delete or anonymize your personal data so that it can no longer be associated with you.`,
        ],
      },
      {
        title: "Who has access to information about you?",
        answers: [
          `We only disclose your information to third parties when necessary to achieve the purposes described in Section 7 and as permitted by law.`,
          `We may share information about you with the following categories of recipients:
            •	Companies within our corporate group: We may share information with other companies within our group to deliver our products and services to you and to assist us in processing and analyzing information about you for the purposes described in Section 7.
            •	Service providers and business partners: We may share information with external service providers and business partners who provide services on our behalf or collaborate with us to deliver our products and services to you, such as for payment processing, hosting, analytics, marketing support, customer service, conducting surveys, and providing security and fraud prevention services. We will ensure that such external parties adhere to appropriate security standards and can only access the information they need to provide services to us or work with us. We will ensure that appropriate agreements are in place to protect your information.
            •	Government agencies and law enforcement: We may disclose information to government and law enforcement agencies or other third parties as required by law or to protect our rights, including responding to legal requests from government authorities.
            •	Business transactions: If we are involved in a merger, acquisition, sale of assets, or other business transaction, we may share your information with third parties involved in such transactions. We will ensure that such parties use your information in accordance with these privacy policies.
            •	Third parties at your direction: We may also share your information with third parties if you provide us with explicit consent.`,
          `We will not sell, rent, or otherwise commercially disclose your personal information to third parties, except as described in these privacy policies or with your consent.`,
        ],
      },
      {
        title: "International Data Transfers",
        answers: [
          `As we operate globally, we may transfer and make your information accessible to our group companies, service providers, and business partners located in countries outside your home country. Please note that privacy laws in some countries may not offer the same level of protection as in your home country. However, we will always take measures to ensure adequate safeguards for the protection of your personal information, such as entering into necessary agreements and complying with legal requirements for transferring personal information to third countries.`,
        ],
      },
      {
        title: "Online and Social Media Marketing",
        answers: [
          `We can utilize online and social media marketing, including advertising on platforms such as Google, Facebook, and LinkedIn, to promote our products and services to you. We may also collaborate with external marketing partners who assist us in running online advertisements. These partners may use cookies and similar technologies to gather information about your interactions with our ads and website to measure and enhance the effectiveness of our marketing campaigns.`,
          `Please note that your interactions with our ads and website may be tracked and recorded for these purposes. We will always adhere to applicable laws and regulations regarding online and social media marketing and take appropriate measures to protect your privacy.`,
        ],
      },
      {
        title: "Automated Decision-Making",
        answers: [
          `We do not make decisions that could significantly impact you based on automatically processed data.`,
        ],
      },
      {
        title: "Cookies and Similar Technologies",
        answers: [
          `We employ cookies and similar technologies on our websites and applications to collect and store information when you use our services. Cookies are small text files stored on your device to gather information about your activities on our websites and applications. These technologies help us enhance your user experience, analyze and optimize our services, and display targeted advertising. For more information about our use of cookies and how you can manage your cookie settings, please refer to our Cookie Policy.`,
        ],
      },
      {
        title: "Your Rights",
        answers: [
          `You have certain rights concerning the information we store and use about you. Depending on applicable law and the type of processing, these rights may include:`,
          {
            type: "table",
            headers: ["Right", "Description"],
            contents: [
              {
                answer: [
                  "Right of Access",
                  "You have the right to request access to the personal information we hold about you.",
                ],
              },
              {
                answer: [
                  "Right of Rectification",
                  "You have the right to have inaccurate or incomplete personal information corrected.",
                ],
              },
              {
                answer: [
                  "Right to Erasure",
                  "Under certain circumstances, you have the right to request the deletion of your personal data by us.",
                ],
              },
              {
                answer: [
                  "Right to Restriction of Processing",
                  "Under certain circumstances, you have the right to restrict the processing of your personal information.",
                ],
              },
              {
                answer: [
                  "Right to Data Portability",
                  "Under certain circumstances, you have the right to receive your personal information in a structured, commonly used, and machine-readable format and transfer this information to another controller.",
                ],
              },
              {
                answer: [
                  "Right to Object",
                  "Under certain circumstances, you have the right to object to the processing of your personal information, including the use of your personal information for direct marketing purposes.",
                ],
              },
              {
                answer: [
                  "Right to Withdraw Consent",
                  "If we process your personal information based on your consent, you have the right to withdraw your consent at any time.",
                ],
              },
            ],
          },
          `If you wish to exercise any of these rights or have questions about how we process your personal information, please contact us using the contact information provided in Section 18.`,
          `Please note that we may need additional information from you to verify your identity before we can fulfil your request. We will endeavor to respond to your request within a reasonable timeframe and in accordance with applicable laws.`,
        ],
      },
      {
        title: "Security",
        answers: [
          `We take the protection of your personal information seriously and implement technical and organizational measures to safeguard your personal information from unauthorized access, loss, alteration, or disclosure. We limit access to your personal information to employees, service providers, and business partners who need this information to provide services on our behalf and who are bound by strict confidentiality. Despite our efforts, we cannot guarantee that information about you is entirely secure. You are responsible for protecting your login credentials and passwords and restricting access to your devices and accounts.`,
        ],
      },
      {
        title: "Contacting Provenant Cloud",
        answers: [
          `If you have any questions, comments, or concerns regarding these privacy policies, our privacy practices, or the exercise of your rights, please contact us at <a href="mailto:privacy@provenant.nl">privacy@provenant.nl<a/>`,
        ],
      },
      {
        title: "Changes",
        answers: [
          `We may update these privacy policies from time to time to account for changes in our business practices, legal or regulatory requirements, or other operational needs. We will publish all changes to these privacy policies on our website and/or inform you through other suitable communication channels. It is important for you to regularly review these privacy policies to stay informed about any updates. `,
        ],
      },
    ],
  };

  return (
    <>
      <Header />
      <CenterWrapper backgroundColor="grey">
        <div className="py-[24px] px-[20px] lg:py-[76px] lg:px-[84px]">
          {isShowBreadcrumbs && parentTitle && (
            <BreadCrumbs>
              <BreadCrumbs.Item value={parentTitle} url={url} />
              <BreadCrumbs.Item value="Privacy Policy" />
            </BreadCrumbs>
          )}

          <p className="typography-h600-bold text-n-900">Privacy Policy</p>
          <p className="typography-h400-bold text-n-900 mt-[16px] lg:mt-[40px] mb-[12px]">
            Effective Date: {privacyData?.effectiveDate}
          </p>
          {privacyData?.intro?.map((introItem) => (
            <p className="typography-h340 text-n-300 mb-[8px] whitespace-pre-wrap">
              {introItem}
            </p>
          ))}

          {privacyData?.questions?.map((q, index) => (
            <>
              <p className="typography-h400-bold text-n-900 mt-[40px] mb-[8px]">
                {index + 1}. {q?.title}
              </p>
              {q.answers?.map((answer) => (
                <GenerateAnswer answer={answer} />
              ))}
            </>
          ))}

          <p className="typography-h340 text-n-300">
            These privacy policies were last updated on{" "}
            {privacyData?.effectiveDate}
          </p>
        </div>
      </CenterWrapper>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
