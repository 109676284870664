import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const SupplyChain = () => {
  const introData = {
    icon: "/assets/svg/product/supply-chain.gif",
    title: "Supply Chain Visibility and Analytics",
    desc: "You're not just getting a service, you're gaining a strategic advantage that will propel your business to new heights of efficiency and profitability",
    illustration: "/assets/svg/product/supply-chain-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/supply-chain.png",
    desc: "Don't let your supply chain be a source of uncertainty and inefficiency. Gain the visibility, analytics, and strategic insights you need to stay ahead of the curve and outperform the competition!",
    provides: [
      {
        icon: "icon-list",
        title: "Advanced Analytics",
        desc: "Knowledge is power, with our advanced analytics capabilities, you'll unlock the power of data-driven decision-making. Our sophisticated algorithms crunch the numbers to uncover hidden patterns, identify trends, and predict future outcomes, empowering you to make smarter, more informed decisions that drive business success.",
      },
      {
        icon: "icon-list",
        title: "Optimization Opportunity",
        desc: "Don't settle for mediocrity, strive for excellence with our optimization capabilities. Our advanced algorithms analyze your supply chain data to identify inefficiencies, streamline processes, and optimize resource allocation, resulting in cost savings, improved productivity, and enhanced customer satisfaction.",
      },
      {
        icon: "icon-sign-doc",
        title: "Actionable Insights",
        desc: " Insight is only valuable if it leads to action. That's why we don't just stop at providing insights, we empower you to take action with intuitive dashboards, customizable reports, and automated alerts. Whether it's reallocating inventory, adjusting production schedules, or renegotiating supplier contracts, we give you the tools you need to turn insights into action and drive tangible results.",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate introData={introData} productDetails={productDetails} />
      <Footer />
    </>
  );
};

export default SupplyChain;
