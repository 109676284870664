import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import "./App.css";
import Home from "./pages/Home";
import GetInTouch from "./pages/GetInTouch";
import AssetManagement from "./pages/product/AssetManagement";
import OrderCollaboration from "./pages/product/OrderCollaboration";
import ManufacturingExecution from "./pages/product/ManufacturingExecution";
import Wholesale from "./pages/industry/Wholesale";
import Plans from "./pages/plans/Plans";
import SupplyChain from "./pages/product/SupplyChain";
import Shipping from "./pages/product/Shipping";
import Integrated from "./pages/product/Integrated";
import Invoicing from "./pages/product/Invoicing";
import ExceptionManagement from "./pages/product/ExceptionManagement";
import Manufacture from "./pages/industry/Manufacture";
import SoftwareImplementation from "./pages/service/SoftwareImplementation";
import CustomSoftware from "./pages/service/CustomSoftware";
import Outsourcing from "./pages/service/Outsourcing";
import ITService from "./pages/service/ITService";
import AboutUs from "./pages/AboutUs";
import TnC from "./pages/legal/TnC";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import DPA from "./pages/legal/DPA";
import Partner from "./pages/Partner";
import Vacancies from "./pages/Vacancies";
import PageNotFound from "./pages/PageNotFound";
import UnderConstruction from "./pages/UnderConstruction";
import SLA from "./pages/legal/SLA";
import Subprocessor from "./pages/legal/Subprocessor";
import MSA from "./pages/legal/MSA";

function App() {
  const urlList = [
    "/home",
    "/getintouch",
    "/asset-management",
    "/order-collaboration",
    "/manufacturing-execution",
    "/wholesale",
    "/plans",
    "/supply-chain",
    "/shipping-management",
    "/integrated-workflow",
    "/invoicing",
    "/exception-management",
    "/manufacturing",
    "/software-implementation",
    "/custom-software",
    "/outsourcing",
    "/it-service",
    "/about-us",
    "/en/general-terms-and-conditions",
    "/en/privacy-statement",
    "/en/processor-agreement",
    "/partner",
    "/vacancies",
    "/page-not-found",
    "/under-construction",
    "/en/service-level-agreement",
    "/en/subprocessors",
  ];

  const RedirectHome = () => {
    const { pathname } = useLocation();

    if (!pathname || pathname === "/" || !urlList?.includes(pathname))
      return <Redirect to="/home" />;
    return <></>;
  };

  return (
    <Router>
      <Switch>
        <Route exact path={["/home"]} component={Home} />
        <Route exact path={["/getintouch"]} component={GetInTouch} />
        <Route exact path={["/asset-management"]} component={AssetManagement} />
        <Route
          exact
          path={["/order-collaboration"]}
          component={OrderCollaboration}
        />
        <Route
          exact
          path={["/manufacturing-execution"]}
          component={ManufacturingExecution}
        />
        <Route exact path={["/wholesale"]} component={Wholesale} />
        <Route exact path={["/plans"]} component={Plans} />
        <Route exact path={["/supply-chain"]} component={SupplyChain} />
        <Route exact path={["/shipping-management"]} component={Shipping} />
        <Route exact path={["/integrated-workflow"]} component={Integrated} />
        <Route exact path={["/invoicing"]} component={Invoicing} />
        <Route
          exact
          path={["/exception-management"]}
          component={ExceptionManagement}
        />
        <Route exact path={["/manufacturing"]} component={Manufacture} />
        <Route
          exact
          path={["/software-implementation"]}
          component={SoftwareImplementation}
        />
        <Route exact path={["/custom-software"]} component={CustomSoftware} />
        <Route exact path={["/outsourcing"]} component={Outsourcing} />
        <Route exact path={["/it-service"]} component={ITService} />
        <Route exact path={["/about-us"]} component={AboutUs} />
        <Route
          exact
          path={["/en/general-terms-and-conditions"]}
          component={TnC}
        />
        <Route
          exact
          path={["/en/privacy-statement"]}
          component={PrivacyPolicy}
        />
        <Route exact path={["/en/processor-agreement"]} component={DPA} />
        <Route exact path={["/en/service-agreement"]} component={MSA} />
        <Route exact path={["/en/service-level-agreement"]} component={SLA} />
        <Route exact path={["/en/subprocessors"]} component={Subprocessor} />
        <Route exact path={["/partner"]} component={Partner} />
        <Route exact path={["/vacancies"]} component={Vacancies} />
        <Route exact path={["/page-not-found"]} component={PageNotFound} />
        <Route
          exact
          path={["/under-construction"]}
          component={UnderConstruction}
        />

        <RedirectHome />
      </Switch>
    </Router>
  );
}

export default App;
