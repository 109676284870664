import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const OrderCollaboration = () => {
  const introData = {
    icon: "/assets/svg/home/collaboration.gif",
    title: "Order Collaboration",
    desc: "An Order Collaboration app gives you that edge by streamlining your ordering process and putting you in control.",
    illustration: "/assets/svg/product/order-collaboration-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/order-collaboration.png",
    desc: "Ordering supplies is a breeze. No more endless emails and phone calls chasing down approvals or order confirmations.  An Order Collaboration app is your secret weapon to streamline that entire process",
    provides: [
      {
        icon: "icon-list",
        title: "Inventory Management",
        desc: "By integrating with inventory systems, the app helps in managing stock levels efficiently. This ensures that orders are fulfilled promptly without the risk of stockouts or overstocking.",
      },
      {
        icon: "icon-list",
        title: "Collaborative Planning",
        desc: "Teams can collaborate on order planning and forecasting using real-time data and analytics provided by the app. This collaborative approach enables businesses to make informed decisions and adapt quickly to changing market demands.",
      },
      {
        icon: "icon-sign-doc",
        title: "Order Customization",
        desc: "Depending on the nature of the business, the app can be customized to cater to specific order workflows and requirements. This flexibility ensures that the app aligns perfectly with the unique needs of each business.",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate introData={introData} productDetails={productDetails} />
      <Footer />
    </>
  );
};

export default OrderCollaboration;
