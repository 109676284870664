// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@mixin fontAttribute(\$weight, \$line-height, \$font-size) {
  @if \$line-height {
    line-height: \$line-height;
  }
  @if \$weight {
    font-weight: \$weight;
  }
  @if \$font-size {
    font-size: \$font-size !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,oBAAoB;EACtB;EACA;IACE,gCAAgC;EAClC;AACF","sourcesContent":["@mixin fontAttribute($weight, $line-height, $font-size) {\n  @if $line-height {\n    line-height: $line-height;\n  }\n  @if $weight {\n    font-weight: $weight;\n  }\n  @if $font-size {\n    font-size: $font-size !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
