import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CenterWrapper from "../../design-system/CenterWrapper";
import BreadCrumbs from "../../design-system/Breadcrumbs";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";

const SLA = () => {
  const { isShowBreadcrumbs, parentTitle, url } = useBreadcrumbs();

  const slaList = {
    effectiveDate: "1 April 2024",
    intro: [
      `This Provenant Cloud Service Level Agreement (the “SLA”) applies to the availability of the Provenant Cloud Platform provided to the Customer. This SLA provides the Customer the sole and exclusive remedy for Provenant Cloud’s failure to meet the standards and commitments established herein. All standards and commitments are subject to the limitations and exclusions set forth in this document and (where relevant) in the Agreement between the parties. Unless otherwise defined herein, capitalized terms used in this SLA have the meaning assigned to them in the General Terms and Conditions, as available at <a href="https://provenantcloud.com/en/general-terms-and-conditions">https://provenantcloud.com/en/general-terms-and-conditions</a>.`,
      `Provenant Cloud reserves the right to update this SLA from time to time. Changes will always prevail from the data of publication at <a href="https://provenantcloud.com/en/general-terms-and-conditions">https://provenantcloud.com/en/general-terms-and-conditions</a>.`,
    ],
    agreements: [
      {
        title: "Provenant Cloud Availability Commitment",
        contents: [
          `•	The Services will be available for a percentage of each calendar month equal to the service level commitment as specifically set forth in this SLA (the “Availability Commitment”).`,
          `•	The Services will adhere to the following Availability Commitment: 99.9%. This is a monthly measured commitment.`,
          `•	The actual availability of the Services for a given month will be calculated according to the following formula (“Availability”):
        Total minutes in the month (“TMM”) = 60 × 24 x (28, 29, 30 or 31)
        Total minutes in the month unavailable (“TMU”)
        And:  Availability = ((TMM – TMU) / TMM) x 100%
        
        For Example:
        In a certain month the TMU of the Services amounted to 26 minutes. The TMM in that month (a month of 31 days) 44,640 minutes. Therefore, the Availability in that month was: Availability = ((44,640 – 26 ) / 44,640) x 100% = 99.94%.`,
          `•	For purpose of the Availability calculation, the Services are deemed to be unavailable if it does not respond to a request issues by Provenant Cloud’s monitoring software (“Unavailable”)`,
        ],
      },
      {
        title: `Exceptions`,
        contents: [
          `The Service will not be considered Unavailable during:
            1.	Provenant Cloud’s set-up or implementation period; or
            2.	Provenant Cloud’s then-current standard maintenance windows (collectively referred to herein as “Scheduled Maintenance”); or`,
          `The Service will not be considered Unavailable for any outage that solely results from:
            1.	Any urgent maintenance performed by Provenant Cloud of which Customer is notified atleast 24 hours in advance; or
            2.	Customer’s request outside of Scheduled Maintenance; or.
            3.	Network unavailability if outside of the Services; or
            4.	Distributed denial of service (DDoS) attacks; or
            5.	Delays or failures due to force majeure.`,
          `The Services will not be considered Unavailable for any outage that is caused by the Customers’ information content or application programming, acts or omissions of Customer or its agents (including any failure to adhere to any of Provenant Cloud’s recommendations or service documentation), failures of equipment or facilities provided by Customer. The Services’ configuration being provided as described in the Service Order (including any Documentation attached thereto) is based on assumptions made by the Customer. As a result, Provenant Cloud (and any authorized partner) will not be responsible, under this SLA or otherwise, for any outages or performance issues caused by inaccuracies in these assumptions, including equipment and software failures and/or performance problems caused by traffic volume or the number of concurrent user sessions.`,
        ],
      },
      {
        title: `Remedies`,
        contents: [
          `Subject to the exceptions provided for this SLA, Customer will have the rights set forth below.
            •	For purposes of this SLA, a Service Credit will be deemed to be an amount equal 1/30th of the Services monthly fee for the contracted Services that is affected (‘Service Credit’). If the Availability of the Services (as calculated in clause 3. above) for a given month is less than the applicable Availability Commitment, the Customer will receive one (1) Service Credit for the first 120-minute increment by which the allowable unavailability is exceeded. Thereafter, for each additional 90-minute increment by which the allowable unavailability is exceeded, Customer will receive one (1) additional Service Credit for the contracted Services.
            •	The sum of Service Credits for a given month will in no event exceed an amount equal to 50% (fifty percent) of the actual monthly fee for the contracted Services. Service Credits will be recognized for billing purposes in the month following the month giving rise to such Service Credits. Customer’s right to receive Service Credit(s) will be Customer’s exclusive remedy for Thales’s failure to satisfy the Availability Commitment.
            
            For Example:
            For the month of April, the TMU was 220 minutes in aggregate.
            Under the Availability Commitment of 99.9%, Service Credits will start to accrue when the Services are Unavailable for more than 44 minutes. However, the Services were Unavailable for 220 minutes. Therefore, Customer is entitled to 1 Service Credit for the Unavailability between 44 and 164 minutes (first 120-minute increment) and 1 for the Unavailability between 164 and 220 minutes (first of the 90-minute increments). This means that the Customer is entitled to a total of 2 Service Credits.
            
            •	In the event the Customers payments are overdue when an outage occurs, Service Credits will accrue, but the Service Credits will not be issued until the Customer has fully complied with its payment obligations.
            •	To receive Service Credits, the Customer must notify their sales representative within 60 days following the date upon which the contracted Services became unavailable; otherwise, the Customer’s right to receive Service Credits with respect to such unavailability will be waived`,
          `If the Subscription was purchased from an Authorized Partner, then the Customer must claim the Service Credit from the applicable Authorized Partner. Any entitlement to Service Credit(s) will be received directly from the Authorized Partner and the Authorized Partner will receive the Service Credit from Provenant Cloud for the Customer’s account. In any event, Provenant Cloud will issue a Service Credit in the form of a credit memo that may be used toward future Subscription fees of Provenant Cloud for the Customer.
            •	The Customer may, no more than once each calendar year and upon 10 calendar days written notice, at its own cost audit Provenant Cloud’s records and data relevant to the calculation of the Availability, for the sole purpose of determining the accuracy of the Availability calculations and, if applicable, the number of Service Credits received. Customer will treat all information made available by Thales to Customer as Confidential Information.`,
        ],
      },
    ],
  };
  return (
    <>
      <Header />
      <CenterWrapper backgroundColor="grey">
        <div className="py-[24px] px-[20px] lg:py-[76px] lg:px-[84px]">
          {isShowBreadcrumbs && parentTitle && (
            <BreadCrumbs>
              <BreadCrumbs.Item value={parentTitle} url={url} />
              <BreadCrumbs.Item value="Service Level Agreement" />
            </BreadCrumbs>
          )}

          <p className="typography-h600-bold text-n-900">
            Service Level Agreement
          </p>
          <p className="typography-h400-bold text-n-900 mt-[16px] lg:mt-[40px] mb-[12px]">
            Effective Date: {slaList?.effectiveDate}
          </p>
          {slaList?.intro?.map((introItem) => (
            <p
              className="typography-h340 text-n-300 mb-[8px] whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: introItem }}
            />
          ))}
          {slaList?.agreements?.map((agreement, index) => (
            <>
              <p className="typography-h400-bold text-n-900 mt-[40px] mb-[8px]">
                <span className="mr-[8px]">{index + 1}.</span>
                {agreement?.title}
              </p>
              {agreement?.contents?.map((content) => (
                <p
                  className="typography-h340 text-n-300 mb-[8px] whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ))}
            </>
          ))}
        </div>
      </CenterWrapper>
      <Footer />
    </>
  );
};

export default SLA;
