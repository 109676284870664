import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const ExceptionManagement = () => {
  const introData = {
    icon: "/assets/svg/product/exception.gif",
    title: "Exception Management",
    desc: "It is the key to maintaining operational excellence and delivering exceptional customer experiences",
    illustration: "/assets/svg/product/exception-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/exception.png",
    desc: "It is all about identifying, managing, and resolving exceptions or disruptions in your business processes. Whether it's a late shipment, a production delay, or an inventory shortage, exception management helps you address issues quickly and efficiently to keep your operations running smoothly.",
    provides: [
      {
        icon: "icon-star",
        title: "Improved Efficiency",
        desc: "Exception management streamlines your business processes by automating routine tasks and standardizing workflows. By reducing manual intervention and streamlining operations, you'll free up valuable time and resources, allowing your team to focus on more strategic tasks and activities.",
      },
      {
        icon: "icon-star",
        title: "Enhanced Customer Satisfaction",
        desc: "Nothing kills customer loyalty faster than a missed deadline or a botched order. Exception management helps you deliver on your promises by ensuring that orders are fulfilled on time and in full, every time. By proactively addressing issues and keeping customers informed, you'll build trust and loyalty that keeps them coming back for more.",
      },
      {
        icon: "icon-star",
        title: "Cost Savings",
        desc: "Exception management helps you identify inefficiencies and bottlenecks in your processes, allowing you to streamline operations and reduce costs. By minimizing the impact of disruptions and avoiding costly mistakes, you'll save time, money, and resources, driving greater profitability and competitiveness.",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate introData={introData} productDetails={productDetails} />
      <Footer />
    </>
  );
};

export default ExceptionManagement;
