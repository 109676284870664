import { useHistory } from "react-router";

import Button from "../design-system/Button";
import CenterWrapper from "../design-system/CenterWrapper";

import "../styles/gradient.scss";

interface IIntroData {
  icon: string;
  title: string;
  topDesc: string;
  bottomDesc: string;
  illustration?: string;
}

interface IServiceDetail {
  image: string;
  desc: string;
  name: string;
}

interface IServiceTemplate {
  introData: IIntroData;
  serviceDetails: IServiceDetail[];
}

const ServiceTemplate = ({ introData, serviceDetails }: IServiceTemplate) => {
  const history = useHistory();

  return (
    <div className="bg-n-100">
      {/* intro of product */}
      <CenterWrapper backgroundColor="grey">
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start py-[24px] pl-[20px] pr-[20px] lg:py-[80px] lg:pl-[100px] lg:pr-[56px]">
          <div className="w-[calc(100vw-40px)] lg:w-[37.5rem] flex flex-col items-center lg:items-start">
            <img
              src={introData?.icon}
              alt={introData?.title}
              className="w-[94px] h-[94px] lg:w-[8.25rem] lg:h-[8.25rem]"
            />
            <p className="mt-[16px] typography-h340-bold text-n-700">
              {introData?.title}
            </p>
            <p className="mt-[8px] typography-h500-bold text-n-700">
              {introData?.topDesc}
            </p>
            <p className="mt-[8px] mb-[20px] text-n-300 typography-h300">
              {introData?.bottomDesc}
            </p>
            <Button
              id="talktosales"
              onClick={() => history.replace("/getintouch")}
              customClass="px-[36px]"
            >
              Talk to Sales
            </Button>
          </div>
          <img
            src={introData?.illustration}
            alt={introData?.title}
            className="w-[9.5rem] lg:w-auto mt-[16px] lg:mt-0"
          />
        </div>
      </CenterWrapper>

      {/* transform  */}
      <CenterWrapper backgroundColor="grey">
        <div className="pl-[20px] pr-[20px] py-[64px] lg:pl-[100px] lg:pr-[9.5rem] lg:py-[64px] flex flex-col items-center lg:items-start gap-[40px] lg:gap-[80px]">
          {serviceDetails?.map((service) => (
            <div className="flex justify-between items-center lg:items-start flex-col-reverse lg:flex-row w-full">
              <div className="w-[calc(100vw-40px)] lg:w-[40.75rem] flex flex-col gap-[12px]">
                <p className="typography-h300-bold text-n-700">
                  {service.name}
                </p>
                <p className="typography-h340 text-n-300">{service.desc}</p>
              </div>
              <img
                src={service.image}
                alt={service.name}
                className="mt-[0] mb-[24px] lg:mt-[-32px] lg:mb-0 w-[15.75rem] lg:w-fit"
              />
            </div>
          ))}
          <Button
            id="talktosales"
            onClick={() => history.replace("/getintouch")}
            customClass="mt-[80px] lg:mt-[-5rem] px-[36px]"
          >
            Talk to Sales
          </Button>
        </div>
      </CenterWrapper>
    </div>
  );
};

export default ServiceTemplate;
