interface IContent {
  answer: string[];
}
interface ITable {
  headers: string[];
  contents: IContent[];
}

const Table = ({ headers, contents }: ITable) => {
  return (
    <table className="table-fixed border-collapse border border-solid border-b-400 w-full">
      <thead>
        <tr>
          {headers?.map((head: string) => (
            <th className="border border-solid border-b-400 bg-b-400 text-n-000 typography-h340">
              {head}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {contents?.map((content: IContent) => (
          <tr>
            {content.answer.map((item: string) => (
              <td className="border border-solid border-b-400 text-n-300 typography-h340 px-[8px] break-words">
                {item}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
