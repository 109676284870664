// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --n-000: #ffffff;
  --n-100: #ededed;
  --n-200: #bababa;
  --n-300: #878787;
  --n-400: #6e6e6e;
  --n-500: #545454;
  --n-600: #3b3b3b;
  --n-700: #222222;

  --r-100: #ffd6d6;
  --r-200: #ffa3a3;
  --r-300: #ff7070;
  --r-400: #ff3b3b;
  --r-500: #f31616;
  --r-600: #c11515;
  --r-700: #8b1818;

  --g-100: #61ffba;
  --g-200: #2effa4;
  --g-300: #00fa8d;
  --g-400: #06c270;
  --g-500: #0c8852;
  --g-600: #0d5435;
  --g-700: #082519;

  --rd-100: #ff70d8;
  --rd-200: #ff3dca;
  --rd-300: #f613b8;
  --rd-400: #c21292;
  --rd-500: #8d166d;
  --rd-600: #5b1548;
  --rd-700: #2f0e26;

  --y-100: #ffe9cc;
  --y-200: #ffd399;
  --y-300: #ffbd66;
  --y-400: #ffa732;
  --y-500: #f2900d;
  --y-600: #b87214;
  --y-700: #825417;

  --b-100: #ccf0ff;
  --b-200: #99e1ff;
  --b-300: #69d1fc;
  --b-400: #3fbdf1;
  --b-500: #1fa8e0;
  --b-600: #2382a9;
  --b-700: #225e77;

  --p-000: #e0cdff;
  --p-100: #b890f9;
  --p-200: #9b68ee;
  --p-300: #8145de;
  --p-400: #6930c2;
  --p-500: #522597;
  --p-600: #3c1b6f;
  --p-700: #261146;
}
`, "",{"version":3,"sources":["webpack://./src/styles/_base.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;;EAEhB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;;EAEhB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;;EAEhB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;;EAEjB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;;EAEhB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;;EAEhB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[":root {\n  --n-000: #ffffff;\n  --n-100: #ededed;\n  --n-200: #bababa;\n  --n-300: #878787;\n  --n-400: #6e6e6e;\n  --n-500: #545454;\n  --n-600: #3b3b3b;\n  --n-700: #222222;\n\n  --r-100: #ffd6d6;\n  --r-200: #ffa3a3;\n  --r-300: #ff7070;\n  --r-400: #ff3b3b;\n  --r-500: #f31616;\n  --r-600: #c11515;\n  --r-700: #8b1818;\n\n  --g-100: #61ffba;\n  --g-200: #2effa4;\n  --g-300: #00fa8d;\n  --g-400: #06c270;\n  --g-500: #0c8852;\n  --g-600: #0d5435;\n  --g-700: #082519;\n\n  --rd-100: #ff70d8;\n  --rd-200: #ff3dca;\n  --rd-300: #f613b8;\n  --rd-400: #c21292;\n  --rd-500: #8d166d;\n  --rd-600: #5b1548;\n  --rd-700: #2f0e26;\n\n  --y-100: #ffe9cc;\n  --y-200: #ffd399;\n  --y-300: #ffbd66;\n  --y-400: #ffa732;\n  --y-500: #f2900d;\n  --y-600: #b87214;\n  --y-700: #825417;\n\n  --b-100: #ccf0ff;\n  --b-200: #99e1ff;\n  --b-300: #69d1fc;\n  --b-400: #3fbdf1;\n  --b-500: #1fa8e0;\n  --b-600: #2382a9;\n  --b-700: #225e77;\n\n  --p-000: #e0cdff;\n  --p-100: #b890f9;\n  --p-200: #9b68ee;\n  --p-300: #8145de;\n  --p-400: #6930c2;\n  --p-500: #522597;\n  --p-600: #3c1b6f;\n  --p-700: #261146;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
