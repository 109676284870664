import { useEffect } from "react";
import { useHistory } from "react-router";

import Button from "../design-system/Button";
import InlineDialog from "../design-system/InlineDialog";

import { checkMobileDevice } from "../hooks/useAgentUtils";
import HeaderMobile from "./HeaderMobile";
import CenterWrapper from "../design-system/CenterWrapper";

interface IMenuItemContent {
  iconName?: string;
  title?: string;
  desc?: string;
  redirectUrl?: string;
}

interface IMenuItem {
  type: string;
  name: string;
  child?: IMenuItemContent[];
  customClass?: string;
  redirectUrl?: string;
}

const GenerateMenuItem = ({
  type,
  name,
  child,
  customClass,
  redirectUrl,
}: IMenuItem) => {
  const history = useHistory();

  if (type === "text") {
    return (
      <p
        className="typography-h300 text-n-700 cursor-pointer"
        onClick={() => redirectUrl && history.replace(redirectUrl)}
      >
        {name}
      </p>
    );
  }

  return (
    <InlineDialog useHover>
      <InlineDialog.Trigger customClass="mr-[8px]" useArrow>
        <p className="typography-h300 text-n-700">{name}</p>
      </InlineDialog.Trigger>
      <InlineDialog.MenuItems vPosition="bottom">
        {child?.map((childItem, index) => (
          <InlineDialog.MenuItem
            customClass={customClass}
            key={index}
            useHover={false}
            onClick={() =>
              childItem?.redirectUrl && history.replace(childItem?.redirectUrl)
            }
          >
            <div
              className={`flex flex-col gap-[8px] ${
                (child?.length > 5 && (index === 0 || index === 1)) ||
                (child?.length < 5 && index === 0)
                  ? ""
                  : "mt-[20px]"
              }`}
            >
              <div className="flex items-center gap-[16px]">
                <img
                  src={childItem?.iconName}
                  alt={childItem?.title}
                  className="h-[2.5rem] w-[2.5rem]"
                />
                <p className="typography-h300-bold text-n-700 m-0">
                  {childItem?.title}
                </p>
              </div>
              {childItem?.desc && (
                <p className="typography-h200 text-n-300">{childItem?.desc}</p>
              )}
            </div>
          </InlineDialog.MenuItem>
        ))}
      </InlineDialog.MenuItems>
    </InlineDialog>
  );
};

const MENU_HEADER = [
  {
    id: "product",
    type: "dropdown",
    name: "Product",
    customClass: "w-[44.25rem] flex basis-1/2",
    child: [
      {
        iconName: "/assets/svg/home/fulfilment.svg",
        title: "Asset Management",
        redirectUrl: "/asset-management",
      },
      {
        iconName: "/assets/svg/product/integrated.svg",
        title: "Integrated Collaborative Workflow",
        redirectUrl: "/integrated-workflow",
      },
      {
        iconName: "/assets/svg/home/collaboration.svg",
        title: "Quotation and Order Collaboration",
        redirectUrl: "/order-collaboration",
      },
      {
        iconName: "/assets/svg/product/invoicing.svg",
        title: "Invoicing",
        redirectUrl: "/invoicing",
      },
      {
        iconName: "/assets/svg/home/manufacturing-execution.svg",
        title: "Manufacturing Collaboration",
        redirectUrl: "/manufacturing-execution",
      },
      {
        iconName: "/assets/svg/home/supply-chain.svg",
        title: "Supply Chain Visibility and Analytics",
        redirectUrl: "/supply-chain",
      },
      {
        iconName: "/assets/svg/home/shipping.svg",
        title: "Shipping Management",
        redirectUrl: "/shipping-management",
      },
      {
        iconName: "/assets/svg/home/exception.svg",
        title: "Exception Management",
        redirectUrl: "/exception-management",
      },
    ],
  },
  {
    id: "plans",
    type: "text",
    name: "Plans",
    redirectUrl: "/plans",
  },
  {
    id: "industry",
    type: "dropdown",
    name: "Your Industry",
    customClass: "w-[30rem]",
    child: [
      {
        iconName: "/assets/svg/home/manufacturing.svg",
        title: "Manufacturing",
        desc: "Various challenges can be alleviated or mitigated through the optimization of their IT technology. Here are some common problems and how IT optimization can address them",
        redirectUrl: "/manufacturing",
      },
      {
        iconName: "/assets/svg/home/wholesale.svg",
        title: "Wholesale",
        desc: "Wholesale businesses face various challenges that can be alleviated or mitigated through the optimization of their IT technology. Here are some common problems and how IT optimization can address them",
        redirectUrl: "/wholesale",
      },
    ],
  },
  {
    id: "service",
    type: "dropdown",
    name: "Service",
    customClass: "w-[24.5rem]",
    child: [
      {
        iconName: "/assets/svg/home/software.svg",
        title: "Software Implementation",
        redirectUrl: "/software-implementation",
      },
      {
        iconName: "/assets/svg/home/software-custom.svg",
        title: "Custom Software Development",
        redirectUrl: "/custom-software",
      },
      {
        iconName: "/assets/svg/home/programmer.svg",
        title: "Software Development Outsourcing",
        redirectUrl: "/outsourcing",
      },
      {
        iconName: "/assets/svg/home/it-service.svg",
        title: "Managed IT Service",
        redirectUrl: "/it-service",
      },
    ],
  },
  {
    id: "about",
    type: "text",
    name: "About us",
    redirectUrl: "/about-us",
  },
];

const GenerateMenu = () => {
  return (
    <div className="flex items-center">
      {MENU_HEADER?.map((menuItem, index) => (
        <>
          <GenerateMenuItem {...menuItem} />
          {index !== MENU_HEADER?.length - 1 && (
            <span className="px-[16px] text-n-300">|</span>
          )}
        </>
      ))}
    </div>
  );
};

const Header = () => {
  const history = useHistory();
  const isMobile = checkMobileDevice() || window.innerWidth < 1152;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isMobile) return <HeaderMobile menuHeader={MENU_HEADER} />;

  return (
    <CenterWrapper>
      <div className="fixed left-0 top-0 bg-n-000 z-[10] w-[100vw] flex justify-center">
        <div className="px-[6rem] py-[20px] flex justify-between items-center w-[78rem]">
          <img
            src="/assets/svg/provenant_logo.svg"
            className="height-auto w-[9.75rem] cursor-pointer"
            alt="provenant logo"
            onClick={() => history.replace("/home")}
          />
          <GenerateMenu />
          <Button id="click" onClick={() => history.replace("/getintouch")}>
            Get In Touch
          </Button>
        </div>
      </div>
      <div className="mb-[56px]"></div>
    </CenterWrapper>
  );
};

export default Header;
