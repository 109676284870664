import { useHistory } from "react-router";

import Button from "../design-system/Button";
import SVGIcon from "../design-system/SVGIcon";
import CenterWrapper from "../design-system/CenterWrapper";

import "../styles/gradient.scss";

interface IIntroData {
  icon: string;
  title: string;
  desc: string;
  illustration?: string;
}

interface IProductDetails {
  image: string;
  desc?: string;
  provides: IIntroData[];
}

interface IProductTemplate {
  introData: IIntroData;
  productDetails: IProductDetails;
  type?: string;
}

const ProductTemplate = ({
  introData,
  productDetails,
  type = "product",
}: IProductTemplate) => {
  const history = useHistory();
  const isProductType = type === "product";

  return (
    <div className="bg-n-100">
      {/* intro of product */}
      <CenterWrapper backgroundColor="grey">
        <div className="flex justify-between py-[20px] pl-[20px] pr-[20px] lg:py-[80px] lg:pl-[100px] lg:pr-[56px] flex flex-col lg:flex-row items-center lg:items-start">
          <div className="w-[calc(100vw-40px)] lg:w-[37.5rem] flex flex-col items-center lg:items-start">
            <img
              src={introData?.icon}
              alt={introData?.title}
              className="w-[5.75rem] h-[5.75rem] lg:w-[8.25rem] lg:h-[8.25rem]"
            />
            <div
              className={`flex flex-col ${
                isProductType ? "items-center lg:items-start" : ""
              }`}
            >
              <p className="mt-[12px] typography-h400-bold text-n-700">
                {introData?.title}
              </p>
              <p
                className={`text-n-700 ${
                  isProductType
                    ? "typography-h400-bold text-center lg:text-left my-[20px]"
                    : "typography-h600-bold my-[4px] lg:my-[20px]"
                }`}
              >
                {introData?.desc}
              </p>
              {isProductType && (
                <Button
                  id="talktosales"
                  onClick={() => history.replace("/getintouch")}
                  customClass="px-[36px]"
                >
                  Talk to Sales
                </Button>
              )}
            </div>
          </div>
          <img
            src={introData?.illustration}
            alt={introData?.title}
            className="w-[9.25rem] lg:w-auto my-[60px] lg:my-0"
          />
        </div>
      </CenterWrapper>

      {/* provide desc */}
      <div className="relative">
        <CenterWrapper backgroundColor="purple">
          <div className="bg-p-400 h-auto lg:h-[33.75rem] flex flex-col lg:flex-row">
            <img
              src={productDetails?.image}
              alt={introData?.title}
              className={productDetails?.desc ? "w-full lg:w-[50%]" : "w-full"}
            />
            {productDetails?.desc && (
              <>
                <div className="flex items-center justify-center w-[calc(100vw-2.5rem)] px-[20px] py-[42px] lg:px-0 lg:py-0">
                  <p className="w-full lg:w-[33.5rem] typography-h350 text-n-000 z-[1]">
                    {productDetails?.desc}
                    <Button
                      id="talktosales"
                      onClick={() => history.replace("/getintouch")}
                      customClass="px-[36px] mt-[20px] lg:mt-[62px] ml-0 lg:ml-[16px]"
                    >
                      Talk to Sales
                    </Button>
                  </p>
                </div>
              </>
            )}
          </div>
        </CenterWrapper>
        <div className="h-[37.5rem] w-[37.5rem] absolute right-0 bottom-0 gradient-product" />
      </div>

      {/* transform  */}
      <CenterWrapper backgroundColor="grey">
        <div className="pt-[40px] pb-[40px] pl-[20px] pr-[20px] lg:pt-[88px] lg:pb-[84px] lg:pl-[100px] lg:pr-[88px]">
          <p className="ml-[8px] mb-[48px] typography-h500-bold text-n-700">
            How can this app transform your business?
          </p>
          <div className="flex flex-col items-center w-full">
            <div className="flex flex-col lg:flex-row gap-[84px]">
              {productDetails?.provides?.map((provide) => (
                <div className="flex flex-col items-center">
                  <div className="w-[80px] h-[80px] rounded-full bg-p-000 flex items-center justify-center">
                    <SVGIcon
                      iconName={provide.icon}
                      size={48}
                      fillColor="var(--p-400)"
                    />
                  </div>
                  <p className="typography-h400-bold text-n-700 mt-[16px] text-center max-w-[18rem] h-auto lg:h-[4.5rem]">
                    {provide.title}
                  </p>
                  <p className="mt-[12px] lg:mt-[24px] typography-h340 text-n-300 text-center">
                    {provide.desc}
                  </p>
                </div>
              ))}
            </div>

            {isProductType && (
              <Button
                id="talktosales"
                onClick={() => history.replace("/getintouch")}
                customClass="px-[36px] mt-[40px] lg:mt-[88px]"
              >
                Talk to Sales
              </Button>
            )}
          </div>
        </div>
      </CenterWrapper>
    </div>
  );
};

export default ProductTemplate;
