import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BreadCrumbs from "../../design-system/Breadcrumbs";
import CenterWrapper from "../../design-system/CenterWrapper";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";

interface ICategories {
  desc: string;
  subCategories?: string[];
  type?: string;
}

const GenerateTerm = ({
  category,
  prefix,
}: {
  category: ICategories;
  prefix: string;
}) => {
  return (
    <>
      <div className="flex mb-[16px]">
        <span className="typography-h340 text-n-300 min-w-[24px]">
          {prefix}
        </span>
        <p
          className="typography-h340 text-n-300 ml-[24px] whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: category?.desc }}
        />
      </div>
      {category?.subCategories?.map((subCategory, index) => (
        <div className="flex ml-[24px] ">
          <span className="mr-[8px] typography-h340 text-n-300 min-w-[36px]">
            {category?.type === "bullet" ? "•" : `${prefix}${index + 1}.`}
          </span>
          <p
            className="typography-h340 text-n-300 mb-[8px] ml-[16px] whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: subCategory }}
          />
        </div>
      ))}
    </>
  );
};

const TnC = () => {
  const { isShowBreadcrumbs, parentTitle, url } = useBreadcrumbs();

  const tncData = {
    effectiveDate: "11 April 2024",
    intro: [
      "Provenant Cloud (a brand of Provenant BV) develops, maintains, and operates a Software-as-a-Service platform that provides on-demand time tracking, expense tracking, and invoicing services (hereinafter referred to as “Services”). These general terms and conditions (hereinafter referred to as T&Cs), including any appendices such as diagrams and/or job descriptions, together with any order confirmation (as defined in Article 1) jointly constitute a binding agreement (hereinafter referred to as “Agreement”). This Agreement sets forth the terms and conditions under which Provenant Cloud provides its Services (as defined in Article 1) to the Customer. Provenant Cloud and the Customer are collectively referred to as the “Parties” and individually as a “Party.”.",
    ],
    terms: [
      {
        title: "Definitions",
        categories: [
          {
            desc: "Unless otherwise defined, the terms used in these T&Cs have the following meanings:",
            type: "bullet",
            subCategories: [
              "<b>Subscription</b>: Access and use of the Provenant Cloud Platform, as well as any Support Services as defined in the respective order confirmation.",
              "<b>Subscription Period</b>: The period for which the Customer has acquired Provenant Cloud Services, as defined in the respective order confirmation.",
              "<b>T&Cs</b>: These present general terms and conditions.",
              "<b>Order Confirmation</b>: The digital document through which the Customer orders a trial subscription, subscription, and/or Professional Services in accordance with the Agreement. The term “order confirmation” refers to both the online-ordered trial subscription and the online order of a final subscription. If another (digital) order confirmation is provided, it explicitly does not constitute acceptance of the Customer’s terms or any other terms that are in addition to or in conflict with the terms of these General Terms and Conditions.",
              "<b>Authorized Representatives</b>: Individuals designated in writing by a party to sign/execute order confirmations on their behalf.",
              `<b>Users</b>: All individuals, functional accounts, and machine accounts registered as user objects in the Provenant Cloud user database for the Customer. User objects may have the status of “active,” “inactive,” or “deleted.”`,
              `<b>Services</b>: All services (including initial setup) defined in the order confirmation and delivered by Provenant Cloud to the Customer using the Provenant Cloud Platform, as well as any additional Professional Services defined in the order confirmation and agreed upon between the parties. Provenant Cloud offers on-demand, cloud-based time tracking, expense tracking, and invoicing services on a software-as-a-service basis.`,
              `<b>GDPR</b>: The General Data Protection Regulation (Regulation (EU) 2016/679) and all other EU laws governing the processing of personal data, which laws may be updated, amended, and replaced by the respective EU institutions from time to time.`,
              `<b>Documentation</b>: The currently available performance descriptions, guides, online materials, data sheets, forms, and related documentation provided by Provenant Cloud to the Customer as part of the subscription, describing the features, technology, functionality, and operation of the Provenant Cloud Platform.`,
              `<b>Intellectual Property Rights</b>: All patents, patent rights, design rights, copyrights, database rights, trade secrets, know-how, trademarks, trade names, trademark rights, service mark rights, author’s personality rights, contract rights, related protection rights, and any applications and rights to registration or protection thereof, that exist at that time or shall exist in the future.`,
              `<b>Costs</b>: The costs described in the order confirmation for the subscription.`,
              `<b>Customer</b>: The natural or legal person who subscribes to the Services.`,
              `<b>License</b>: A limited, non-exclusive, non-transferable, non-sublicensable permission to access and use the Provenant Cloud Platform during the subscription period.`,
              `<b>Notification</b>: Any notification, consent, approval, or other communication that is intended to have legal effects and must be made in the context of the Agreement.`,
              `<b>Personal Data</b>: The meaning is described in accordance with the relevant data protection laws and includes, without limitation, all data or information (regardless of the medium in which it is contained and whether it stands alone or in combination) that relates to an identified or identifiable natural person.`,
              `<b>Trial Subscription</b>: Provenant Cloud offers the opportunity to enter into a non-binding trial subscription. A trial subscription is a subscription without an obligation to use or pay and is limited to a specified period. After this period, the trial subscription is not automatically converted into a paid subscription.`,
              `<b>Professional Services</b>: All Professional Services, including but not limited to consultancy, training, development, implementation assistance, technical support, or customization of IT services, provided by Provenant Cloud to the Customer on a time-and-materials basis or on a fixed-price basis, as specified and defined in the respective order confirmation.`,
              `<b>Service Level Agreement</b>: The agreement governing maintenance and Support Services that the Customer enters into with Provenant Cloud regarding the Provenant Cloud Platform as part of the subscription.`,
              `<b>Software</b>: The object code version of the Provenant Cloud programs or modules specified in an order confirmation, as well as any modified, updated, or enhanced versions thereof provided by Provenant Cloud to the Customer as part of the subscription.`,
              `<b>Start Date</b>: The date from which the Customer can use the subscription, with the subscription period being determined according to the information in the respective order confirmation.`,
              `<b>Support</b>: The provision of oral (telephone) and written advice and support regarding the use and operation of the Services.`,
              `<b>Support Services</b>: The maintenance and Support Services that the Customer uses within the framework of the subscription with Provenant Cloud regarding the Provenant Cloud Platform and as defined in the Service Level Agreement.`,
              `<b>Provenant Cloud Platform</b>: The Provenant Cloud platform, including any software used to provide the Services to Customers in accordance with these T&Cs.`,
              `<b>Agreement</b>: The combination of an accepted order confirmation and the acknowledged General Terms and Conditions, including all related appendices, diagrams, and job descriptions.`,
              `<b>Confidential Information</b>: Non-publicly accessible information received by one party from the other party or to which it has access under the Agreement, including but not limited to each party’s data and its proprietary software and computer operations, machinery and operating instructions, all codes, inventions, algorithms, business concepts, workflows, marketing, financial, business, and technical information, terms and prices under the Agreement, verification data regarding the use of the software, and other information related to the business or technology of either party that is either clearly marked as confidential or is of a nature that an expert would understand to be confidential.`,
              `<b>Website</b>: The Provenant Cloud website, accessible at <a href="www.provenant.nl">www.provenant.nl</a>, and all subsidiary pages. The Provenant website, accessible at <a href="www.provenant.nl">www.provenant.nl</a>, and all subsidiary pages.`,
            ],
          },
          {
            desc: `For the interpretation of the Agreement or the T&Cs, it is irrelevant whether the above-defined terms or other words are used in the singular or plural or in a specific composition. A reference to a “person” includes a reference to an individual, a company, an association, or a partnership (whether registered or not), as well as the legal representatives and/or successors of that person.`,
          },
          {
            desc: `The headings of the sections are for clarity only and do not affect the content or interpretation of the T&Cs and the Agreement. `,
          },
        ],
      },
      {
        title: "Applicability",
        categories: [
          {
            desc: "These T&Cs apply to any use of the Service and the Website, subscriptions, as well as any other Services provided to the Customer by Provenant Cloud. Any terms and conditions of the Customer are expressly rejected.",
          },
          {
            desc: `These T&Cs and the subscription can only be amended and/or supplemented by Provenant Cloud.`,
          },
          {
            desc: `Provenant Cloud is entitled to change and/or supplement these T&Cs and/or additions thereto annually. The most up-to-date T&Cs can be found on the website or shall be brought to the attention of the Customer and/or user during the use of the Services. If the Customer continues to use the Service after the amendment and/or supplementation of these T&Cs, the Customer irrevocably accepts the amended and/or supplemented T&Cs.`,
          },
          {
            desc: `If any provision of these T&Cs is found to be void or unenforceable or is otherwise invalid or partially invalid for any other reason, the remaining provisions of the T&Cs shall remain unaffected. Provenant Cloud shall replace the invalid provision with a valid provision whose legal effects, taking into account the content and purpose of these T&Cs, correspond as closely as possible to those of the invalid provision.`,
          },
        ],
      },
      {
        title: "Provenant Cloud Platform",
        categories: [
          {
            desc: "After the (digital) acceptance of the order confirmation and agreement to the T&Cs of the Agreement, Provenant Cloud enables the Customer to access and use the Provenant Cloud Platform on the basis of a subscription or trial subscription.",
          },
          {
            desc: `A subscription concluded by the Customer commences on the start date specified in the respective order confirmation and is valid for the (initial) subscription period. Unless otherwise specified in the respective order confirmation, the subscription period automatically renews for one (1) year each time, unless one of the parties informs the other party in writing at least sixty (60) days before the expiry of the respective subscription period that it does not wish to renew.`,
          },
          {
            desc: `The Customer acknowledges that the Agreement is primarily a service agreement and that Provenant Cloud shall not deliver copies of the software as part of the subscription.`,
          },
          {
            desc: `3.4.	The Customer acknowledges that each subscription is provided on a non-exclusive basis. Nothing shall prevent or limit Provenant Cloud from providing subscription Services or other technology, including all features or functions developed for the Customer, to other parties.`,
          },
        ],
      },
      {
        title: "Order Confirmation",
        categories: [
          {
            desc: "An order confirmation is the digital document that forms the legal basis for the provision of the Services to the Customer. The order confirmation refers to these T&Cs (including appendices) and describes the respective completed subscription, start date, (original) (trial) subscription period, and subscription costs.",
          },
          {
            desc: `An order confirmation is digitally signed by the authorized representatives of the parties. Each party shall inform the other of those individuals who have been designated as authorized representatives, either singly or jointly.`,
          },
          {
            desc: `If the parties wish to impose supplementary or additional terms other than those set out in these T&Cs, this must be expressly stated in writing in the respective order confirmation, including a declaration that this has been jointly agreed upon as a deviation from the (specific section(s) of these) T&Cs.`,
          },
        ],
      },
      {
        title: "License",
        categories: [
          {
            desc: "Subject to the terms of the Agreement, including but not limited to the payment of all applicable fees, Provenant Cloud hereby grants the Customer a limited, non-exclusive, non-transferable, and non-sublicensable right (hereinafter referred to as “License”) to access and use the Provenant Cloud Platform during the subscription period for the number of users specified in the respective order confirmation.",
          },
          {
            desc: `No license is granted for the source code of the software.`,
          },
          {
            desc: `Unless jointly agreed otherwise`,
            type: "numbering",
            subCategories: [
              "The Customer may not copy, edit, adapt, modify, translate, or create derivative works from the software.",
              "The Customer may not distribute, sell, resell, lend, rent, license, operate as a service bureau, offer as a managed service, sublicense, or otherwise use the software for third parties (including through multiplexing or pooling), unless expressly agreed upon by Provenant Cloud.",
              `The Customer may not conduct reverse engineering, decompilation, or disassembly of the software or otherwise attempt to derive the source code of the software (except to the extent expressly permitted by applicable law).`,
              `The Customer may not disclose results of benchmark tests, technical results, or other performance data regarding the software without the prior written consent of the licensors.`,
              `The Customer may not take actions that would make the software publicly accessible. `,
              `The Customer may not remove, modify, or obscure trademarks of Provenant Cloud, its licensors, or suppliers contained in the software.`,
              `The Customer may not use the software in a manner that violates the terms of the Agreement.`,
              `The Customer may not use the software for unlawful purposes.`,
            ],
          },
          {
            desc: `The Customer may not grant access to or use of the Provenant Cloud Platform to anyone other than the Customer, its employees, and business partners. Usage must comply with the terms and restrictions in these T&Cs.`,
          },
          {
            desc: `The Customer agrees to be responsible for the compliance with the agreed terms by all employees, suppliers, and agents of the Customer. The Customer further agrees to indemnify, hold harmless, and defend Provenant Cloud and its licensors against any claims or suits, including legal costs and expenses arising from or in connection with the Customer’s unlawful use of the Provenant Cloud Platform.`,
          },
        ],
      },
      {
        title: "Support",
        categories: [
          {
            desc: `Subject to the payment of all applicable fees by the Customer, Provenant Cloud shall provide support services to the Customer as part of the subscription, in accordance with the then-current. <a href="/en/service-level-agreement">Service Level Agreement</a> (English). The corresponding fees are specified in the respective order confirmation.`,
          },
          {
            desc: `Provenant Cloud may suspend the provision of Support Services if substantial amounts owed by the Customer to Provenant Cloud under the Agreement are overdue, and Provenant Cloud has notified the Customer in writing of its intention to suspend Support Services for this reason:`,
            subCategories: [
              "Support does not include (full) explanations of the application possibilities of the service to users, the provision of implementation assistance, advice on configuration and/or security of workstations and/or infrastructure, or the setup or development of templates. Additionally, no support is provided for other software or services other than those provided by Provenant Cloud or for the use of the service on mobile devices.",
              "Support is provided via email (support@provenant.nl) or by phone. Telephone support is exclusively available during business hours (Monday to Friday, 8:30 a.m. to 5:00 p.m.). Provenant Cloud shall make reasonable efforts to respond to inquiries and advise users. However, Provenant Cloud makes no guarantee of the accuracy and completeness of the responses to questions and/or advice provided to the user. Provenant Cloud is not liable for damages arising from or related to the provision of the support described in this article, including but not limited to remote support, answering questions, providing advice, and taking over the user’s computer or other devices.",
            ],
          },
          {
            desc: `Provenant Cloud uses a data backup system. The Customer accepts that the data backup system includes only the functionality and other features as they exist and that the data is stored only in the manner and location it is found at the time of use of the service (on an “as-is” basis). The Customer or the user can access this data backup and/or any other material contained therein. However, data or other material processing is excluded. In individual cases, Provenant Cloud may decide to offer the service exclusively through the data backup system.`,
            subCategories: [
              "Provenant Cloud is never obligated to read and/or perform any other investigation regarding a data backup. However, upon the Customer’s request, Provenant Cloud may read and/or investigate a data backup and/or log files or perform a complete restoration of the data backup. Provenant Cloud shall charge the Customer additional costs for reading, investigating, and/or restoring a backup copy and/or log file.",
            ],
          },
        ],
      },
      {
        title: "Processing of Personal Information",
        categories: [
          {
            desc: `The parties commit at all times to fulfilling their own obligations under the General Data Protection Regulation (GDPR) and/or other applicable data protection laws and regulations. The parties are responsible for compliance with these laws and regulations when processing personal data under the contract. Provenant Cloud has outlined its obligations in a <a href="/en/privacy-statement?source=general-terms-and-conditions">Privacy Policy</a>.`,
          },
          {
            desc: `If, as part of the contract performance, Provenant Cloud has access to personal data, it shall act as a data processor on behalf of the Customer for its processing. Provenant Cloud shall process personal data at all times in full compliance with the GDPR and other applicable data protection laws. If Provenant Cloud processes personal data of individuals from the European Economic Area, the parties hereby agree that such processing shall be carried out in accordance with the terms of the <a href="/en/processor-agreement?source=general-terms-and-conditions">Data Processing Agreement.</a>`,
          },
          {
            desc: `The Customer shall indemnify Provenant Cloud from the third-party claims arising from or in connection with the processing of data and/or (other) personal data.`,
          },
        ],
      },
      {
        title: "Duration and Termination",
        categories: [
          {
            desc: `The Agreement is concluded on the date specified in the order confirmation (hereinafter referred to as the “Effective Date”) and commences on that day, remaining in force until all subscriptions have expired or been terminated.`,
          },
          {
            desc: `The Customer has the right to terminate the Agreement upon observing a termination notice period as agreed upon and specified in the order confirmation. If the Customer does not terminate the subscription, it shall be automatically extended for the duration specified in the order confirmation.`,
          },
          {
            desc: `Regardless of the provisions of Article 6:265 of the Dutch Civil Code (Nederlandse Burgerlijk Wetboek), the parties may immediately terminate and/or suspend the Agreement and/or their obligations under this Agreement if the other party:`,
            type: "numbering",
            subCategories: [
              "is declared insolvent",
              "requests or is granted (temporary) suspension of payment or offers a (WHOA – Homologation of Private Agreements) agreement",
              "is in (involuntary) liquidation or dissolution;",
              "has ceased or terminated its business activities;",
              "breaches a material provision of the Agreement, which has not been remedied within 30 (thirty) days after receiving notice;",
              "fails to comply with its obligations under this Agreement despite a request to do so (sufficiently)",
              "or there is reason to believe that the other party shall not fulfill these obligations.",
              "behaves fraudulently, violates the law and/or regulations, or behaves in a manner that is inappropriate and reasonably expected to be unacceptable for the other party to continue the contract.",
            ],
          },
          {
            desc: `Upon expiration or termination of the contract for any reason:`,
            type: "numbering",
            subCategories: [
              "All subscription(s) shall be automatically terminated, and the Customer shall no longer have access to the Provenant Cloud Platform.",
              "The Customer’s payment obligation for all costs due up to the termination date shall remain in full force and shall not be suspended or canceled.",
              "Any unpaid amount owed by the Customer under the contract shall become due immediately upon termination of the contract and must be paid by the Customer within 30 (thirty) days after termination.",
              "Articles 4, 5, 6, 7, 8, 9, and 10 of these terms and conditions shall continue to be in effect after termination of the contract.",
              "The termination/termination of the Agreement does not affect the rights or obligations of the parties that are intended to remain in effect after termination under the nature and scope of the Agreement.",
            ],
          },
          {
            desc: `The Customer is responsible for properly storing and retaining all data entered into the Provenant Cloud Platform before expiration or termination of the Agreement. Provenant Cloud shall not retain the data. Upon written request from the Customer and for an additional fee, data may be provided to the Customer by download and/or other means, at the discretion of Provenant Cloud and only if Provenant Cloud still has this data available. However, Provenant Cloud is not obligated to comply with such a request, regardless of an offer to reimburse the costs.`,
          },
        ],
      },
      {
        title: "Rates and Payment",
        categories: [
          {
            desc: `The Customer shall pay to Provenant Cloud the rates specified in the order confirmation in accordance with this section and other terms of the Agreement. The mentioned prices are always exclusive of value-added tax.`,
          },
          {
            desc: `The Customer shall pay to Provenant Cloud all amounts due under this Agreement within fourteen (14) days after receipt of the invoice for these amounts. All payments must be made in euros unless otherwise stated in the order confirmation. Late payment of any amounts due shall incur default interest at the minimum rate of 1.5% per month or the maximum rate allowed by applicable law, from the due date until payment.`,
          },
          {
            desc: `If the Customer is in arrears with two installment payments and has received the third reminder, Provenant Cloud has the right to temporarily suspend the account. The Customer shall receive instructions by email on how to pay the invoices and when the Customer’s account shall be reactivated.`,
          },
          {
            desc: `If the Provenant Cloud Platform is used by more users than specified and agreed upon by the Customer, the Customer shall immediately inform Provenant Cloud of the exact number of users and pay any additional costs for the use by such users. Provenant Cloud regularly measures the number of users. If the number of users measured by Provenant Cloud is higher than the number agreed upon with the Customer, the higher number shall be decisive, and the costs shall be invoiced to and paid by the Customer based on this number.`,
          },
          {
            desc: `In the event that Provenant Cloud’s employees or subcontractors need to travel to the Customer’s facilities in connection with the provision of the Services, the Customer shall reimburse Provenant Cloud for all reasonable travel expenses and related expenses.`,
          },
          {
            desc: `When the Customer pays by direct debit, the Customer must ensure that there are sufficient funds in the account. Payment by direct debit shall be debited within seven (7) days after the invoice is sent, regardless of the applicable payment period. In case of a failed direct debit and/or revocation of a direct debit, Provenant Cloud is entitled to charge the Customer an administrative fee of up to €5 per invoice.`,
          },
          {
            desc: `Provenant Cloud reserves the right to change rates. Provenant Cloud shall send the Customer a corresponding announcement by email at least 1 month in advance.`,
          },
          {
            desc: `If a Customer has objections to an invoice, they must notify Provenant Cloud by email at <a href="mailto:support@provenant.nl">support@provenant.nl</a> within one month of the invoice date. Objections to an (partial) invoice do not release the payment obligation.`,
          },
        ],
      },
      {
        title: "Force Majeure",
        categories: [
          {
            desc: `Failure to fulfill the obligations under the subscription cannot be attributed to Provenant Cloud, and Provenant Cloud shall not assert any claims for damages if the failure is due to force majeure as referred to in Article 6:75 of the Dutch Civil Code (Nederlandse Burgerlijk Wetboek). Force majeure includes, but is not limited to, failures by Provenant Cloud’s suppliers who are themselves in a state of force majeure, power failures, internet, telephone, cable, and other connection disruptions, disturbances due to excavation work not carried out by Provenant Cloud, (distributed) denial-of-service attacks, license refusals, government actions, regulations, and strikes, as well as other events that reasonably prevent Provenant Cloud from providing Services beyond its control.`,
            subCategories: [
              "If a failure to fulfill the obligations under the subscription by Provenant Cloud due to force majeure lasts for more than 14 days, either party may terminate the subscription, provided that they have notified their intention to terminate at least 14 days before the termination, and Provenant Cloud has not fulfilled the obligations under the subscription in the meantime.",
            ],
          },
        ],
      },
      {
        title: "Intellectual Property",
        categories: [
          {
            desc: `The Provenant Cloud Platform, including all copies, improvements, adaptations, modifications, and derivative works thereof, as well as the documentation, is protected by (international) copyrights, patents, trade secrets, and other intellectual property rights. Provenant Cloud owns and retains all rights, titles, and interests in the intellectual property rights of the Provenant Cloud Platform, as well as any improvements, adaptations, or derivative works thereof.`,
          },
          {
            desc: `The license does not grant ownership rights to the Provenant Cloud Platform, software, or documentation and does not constitute a sale of rights. The Customer does not acquire any express or implied rights to the Provenant Cloud Platform, except as expressly set forth in the Agreement. The license does not grant the Customer any rights to any improvement or update of the Provenant Cloud Platform, unless expressly agreed upon in the respective order confirmation.`,
          },
          {
            desc: `To the extent that new intellectual property is created as part of a subscription, this shall exclusively belong to Provenant Cloud. If such intellectual property does not automatically belong to Provenant Cloud, the Customer shall provide all necessary cooperation to transfer full intellectual property rights to Provenant Cloud.`,
          },
          {
            desc: `To the extent that new intellectual property is created as part of a subscription, this shall exclusively belong to Provenant Cloud. If such intellectual property does not automatically belong to Provenant Cloud, the Customer shall provide all necessary cooperation to transfer full intellectual property rights to Provenant Cloud.`,
          },
          {
            desc: `Provenant Cloud is entitled to take technical measures to protect its subscriptions from unauthorized use. The Customer is not allowed to remove or bypass such technical measures.`,
          },
          {
            desc: `The Customer warrants not to take any action that infringes on the intellectual property rights of Provenant Cloud and/or its licensors and/or suppliers or renders these rights invalid or jeopardizes them.`,
          },
          {
            desc: `Provenant Cloud, as well as its licensors and suppliers, reserve all rights to the Provenant Cloud Platform not expressly granted to the Customer in the Agreement, and no other licenses or rights are granted by implication, estoppel, or otherwise.`,
          },
        ],
      },
      {
        title: "Terms and Termination",
        categories: [
          {
            desc: `This Agreement shall enter into force on the effective date of the Agreement and shall remain in force for the entire term of the Agreement.`,
          },
          {
            desc: `The expiry or termination of the Agreement in accordance with the agreed terms in the relevant service order shall automatically result in the expiry or termination of this Agreement, unless the Data Processor continues to process personal data or has processed personal data for any reason after the termination of the Agreement, in which case the data processing agreement shall remain valid for as long as the Data Processor processes personal data.`,
          },
          {
            desc: `The Data Processor is entitled to terminate this Agreement if the Data Controller makes changes to the purpose of this Agreement as set out in the appendix and/or the instructions, and the Data Processor cannot comply with such changes for operational reasons or considers such changes unacceptable for operational reasons. If such a situation arises, the Parties shall first negotiate in good faith to resolve the matter in a manner acceptable to both Parties.`,
          },
          {
            desc: `Upon termination or expiry of this Agreement (for any reason whatsoever), the Data Processor shall promptly return all personal data to the Data Controller or, at the request of the Data Controller, delete all personal data unless the Parties have agreed otherwise in writing. The Data Processor is not obliged to return personal data that the Data Controller has provided to the Data Processor by means other than those agreed between the Parties.`,
          },
          {
            desc: `Any provision of this Agreement that imposes an obligation after termination (or expiry) of this Agreement, including but not limited to Article 4 (“Confidentiality”), Article 14 of the General Terms and Conditions (“Breach”), and this section, shall remain in effect after termination (or expiry) of this Agreement.`,
          },
        ],
      },
      {
        title: "Warranties",
        categories: [
          {
            desc: `Provenant Cloud declares and guarantees that it shall provide the subscription in accordance with the general industry standards in a professional manner.`,
          },
          {
            desc: `Provenant Cloud does not guarantee that the subscription shall run error-free or without interruptions, that Provenant Cloud shall correct all errors, or that the software is error-free. The software is provided without any warranty of defects, and Provenant Cloud, on its own behalf and on behalf of its partners, disclaims all express or implied warranties and liabilities regarding the software, including but not limited to warranties of merchantability, design, or fitness for a particular purpose, warranties arising from the course of dealing, usage, or trade practice, or warranties regarding non-infringement of third-party rights. The Customer acknowledges that Provenant Cloud has no control over the transmission of data via communication facilities, including the internet, and that the subscription may be subject to limitations, delays, and other issues associated with the use of such communication facilities. This section sets forth the sole and exclusive warranty provided by Provenant Cloud (express or implied) with respect to the subject matter of the agreement. Neither Provenant Cloud nor its licensors or other suppliers guarantee or warrant that the operation of the subscription shall be uninterrupted, virus-free, or error-free, nor shall Provenant Cloud or its service providers be liable for unauthorized modifications, theft, or destruction of Customer or user data, files, or programs. Furthermore, Provenant Cloud makes no warranty regarding the use or results of the use of the Provenant Cloud Platform, software, or related documentation in terms of correctness, accuracy, reliability, or otherwise. No oral or written information or advice from Provenant Cloud or its authorized representatives creates a warranty or extends the scope of this warranty in any way.`,
          },
          {
            desc: `Provenant Cloud’s sole obligation with respect to an error in the Services is, if applicable, to take the actions specified in the Service Level Agreement and, where applicable, in the corresponding order confirmation, to remedy the error.`,
          },
        ],
      },
      {
        title: "Infringement",
        categories: [
          {
            desc: `Provenant Cloud shall, at its own expense, defend any claim brought by a third party against the Customer alleging that the Provenant Cloud Platform infringes a patent, intellectual property right, or copyright or misappropriates a third party’s trade secret. Furthermore, Provenant Cloud shall pay the costs and damages awarded to the Customer in such a claim that are specifically attributable to such claim, or the costs and damages agreed upon in a financial settlement of such claim. The foregoing obligations are conditioned on the Customer:`,
            subCategories: [
              `promptly notifying Provenant Cloud in writing of such a claim;`,
              `allowing Provenant Cloud to have exclusive control over the defense and any related settlement negotiations; and`,
              `cooperating and, at Provenant Cloud’s request and expense, contributing to such defense. In such legal action, defense, or settlement, Provenant Cloud shall take into account the Customer’s commercial interests.`,
            ],
          },
          {
            desc: `Notwithstanding the above, Provenant Cloud has no obligations under this Article or otherwise with respect to any claim based on:`,
            subCategories: [
              `any use of the Provenant Cloud Platform not in accordance with the Agreement;`,
              `any use of the Provenant Cloud Platform in conjunction with products, devices, software, or data not provided by Provenant Cloud, where such infringement would have been avoided by the use of other products, devices, software, or data;`,
              `any use of a version of the Provenant Cloud Platform that does not correspond to the most recent version provided to the Customer; or`,
              `any alteration to the Provenant Cloud Platform or software by any person other than Provenant Cloud or its authorized representatives or subcontractors.`,
            ],
          },
          {
            desc: `This Article governs Provenant Cloud’s entire liability and the Customer’s exclusive remedy for infringement claims.`,
          },
        ],
      },
      {
        title: "Liability",
        categories: [
          {
            desc: `Provenant Cloud’s liability to the Customer is limited to direct damages arising from an attributable failure to perform the Agreement.`,
          },
          {
            desc: `Provenant Cloud is in no way liable for the reimbursement of indirect or consequential damages of any kind, including, but not limited to, loss of revenue or profit, damages due to savings not achieved, damages due to delay, or data loss, whether such damages arise from contract, tort, or any other liability theory, whether or not the party was aware of the possibility of such damages.`,
          },
          {
            desc: `Notwithstanding the foregoing, Provenant Cloud’s liability for damages of any kind is limited to a maximum of the amount (excluding VAT) of the fees paid by the Customer in the twelve (12) months preceding the date of the damage event. In no event shall the total compensation for any damages exceed the amount paid by Provenant Cloud’s liability insurance.`,
          },
          {
            desc: `Provenant Cloud is not liable for damages caused by the acts or omissions of third parties engaged by the Customer in the performance of the Agreement.`,
          },
          {
            desc: `Provenant Cloud’s limitation of liability under this Agreement shall not apply if and to the extent the damage is caused by intent or gross negligence on the part of Provenant Cloud’s management. The burden of proof for intent or gross negligence lies with the Customer.`,
          },
          {
            desc: `Provenant Cloud’s liability for an attributable failure to perform the Agreement shall only arise if the Customer promptly and reasonably notifies Provenant Cloud in writing and sets a reasonable period for remedying the failure, and Provenant Cloud continues to fail to meet its obligations after that period has expired. The notice of non-performance must include a detailed description of the failure so that Provenant Cloud can respond appropriately.`,
          },
          {
            desc: `A prerequisite for any claim for damages is that the Customer reports the damage to Provenant Cloud in writing within thirty (30) days of discovery. Any claim for damages arising from damage caused by Provenant Cloud shall expire in any event twelve (12) months after the event giving rise to the liability. This does not affect the provisions of Section 6:89 of the Dutch Civil Code.`,
          },
          {
            desc: `The Customer indemnifies Provenant Cloud from claims by third parties arising from a breach of this Agreement by the Customer.`,
          },
        ],
      },
      {
        title: "Confidentiality",
        categories: [
          {
            desc: `During the term of this Agreement and for a period of 5 (five) years after its termination, both parties shall maintain confidentiality and shall not use the confidential information of the other party for purposes unrelated to this Agreement or disclose such information to third parties (except to employees, authorized representatives, or contractors of the party who need the information and are subject to confidentiality obligations at least as restrictive as those contained herein). Each party agrees to take all reasonable measures to ensure that confidential information is not disclosed or disseminated by its employees, contractors, or authorized representatives in violation of the terms of the Agreement.`,
          },
          {
            desc: `Both parties may disclose the confidential information of the other party:`,
            subCatgories: [
              `in response to a valid order or request of a court or other governmental or regulatory authority,`,
              `as otherwise required by law; or`,
              `as necessary to preserve the rights of one of the parties under this Agreement.`,
              `The party making a disclosure shall promptly notify the other party and provide an opportunity to object or seek a protective order to the extent permitted by applicable law.`,
            ],
          },
          {
            desc: `Confidential information does not include information that:`,
            subCatgories: [
              `is or becomes part of the public domain through no action or omission of the receiving party;`,
              `was in the receiving party’s lawful possession without restriction prior to disclosure and was not directly or indirectly received from the disclosing party;`,
              `was lawfully disclosed to the receiving party by a third party without restriction; or`,
              `was independently developed by the receiving party without access to the confidential information.`,
            ],
          },
          {
            desc: `Unless otherwise permitted or necessary to achieve the purposes of the Agreement, upon request of the disclosing party, the receiving party shall, at its expense and at its option, either destroy (and confirm in writing) or return to the disclosing party all confidential information, including all documents or media containing such confidential information, as well as all copies or extracts thereof; provided, however, that the receiving party may retain copies of computer records and files of confidential information created as part of automatic archival and backup processes or retain a backup copy of such confidential information as required by law, regulation, or internal compliance policies; in which case, such confidential information shall continue to be subject to the confidentiality obligation even after the termination of the Agreement.`,
          },
          {
            desc: `Provenant Cloud may identify the Customer as a Provenant Cloud Customer on the Provenant Cloud website as well as in written and/or electronic marketing materials, press releases, case studies, whitepapers, and webinars related to Provenant Cloud products and/or Services. Any such use shall be in accordance with the Customer’s branding guidelines or requirements as communicated by Provenant Cloud from time to time. `,
          },
        ],
      },
      {
        title: "Notification",
        categories: [
          {
            desc: `Any notice, consent, approval, or other communication with legal effect required by this Agreement to be given (hereinafter “Notice”) must be in writing, including via email to the address(es) specified in the relevant order confirmation.`,
          },
          {
            desc: `Either party may change the addresses to which Notices shall be sent by giving notice to the other party in the manner provided in this Article. Notices shall be written in the Dutch or English language.`,
          },
        ],
      },
      {
        title: "Assignment",
        categories: [
          {
            desc: `The Customer may not assign the license or any portion of its rights or obligations under this Agreement, whether by operation of law or otherwise, without the prior written consent of Provenant Cloud. Any attempted assignment in violation of the foregoing is null and void.`,
          },
          {
            desc: `Provenant Cloud shall have the right to assign this Agreement to a successor of its business or assets related to the Agreement, whether by merger, sale of assets, sale of business shares, reorganization, or otherwise.`,
          },
          {
            desc: `All of the provisions of this Agreement shall be binding upon and inure to the benefit of the respective successors and permitted assigns of Provenant Cloud and the Customer.`,
          },
        ],
      },
      {
        title: "Miscellaneous",

        categories: [
          {
            desc: `Agreement (including all appendices, attachments, schedules, and work orders) constitutes the entire agreement between the parties with respect to its subject matter and supersedes all prior agreements between them with respect to the subject matter of the Agreement. Amendments, supplements, or modifications to this Agreement shall be binding only if made in writing and signed by both parties. In the event of any conflicts, inaccuracies, errors, or omissions between these terms and conditions and an order confirmation, the documents and the changes contained therein shall prevail and apply in the following order: (a) order confirmation; (b) terms and conditions; and (c) appendix (if applicable). The provisions of a purchase order or similar document provided by the Customer to Provenant Cloud shall not be effective unless fully agreed upon and signed by Provenant Cloud.`,
          },
          {
            desc: `If any provision of this Agreement is found to be invalid or unenforceable, this shall not affect the lawful validity of any other provisions of this Agreement. The unenforceability or invalidity of a provision shall not affect the enforceability or validity of any other provision of the Agreement.`,
          },
          {
            desc: `Failure by a party to exercise or enforce a right or remedy under the Agreement does not constitute a waiver of that right or remedy and does not prevent the future exercise or enforcement of that right or remedy.`,
          },
          {
            desc: `The relationship between the parties is that of independent contractors. This Agreement is not to be construed as creating a joint venture, partnership, agency, or employment relationship between the parties.`,
          },
          {
            desc: `This Agreement shall be governed by and construed in accordance with Dutch law. All claims, lawsuits, or proceedings arising out of the Agreement or its subject matter shall be litigated in the competent court in Amsterdam, the Netherlands.`,
          },
          {
            desc: `These terms and conditions shall enter into force as of 01-04-2024 at 09:00 AM (CET). Test subscriptions and subscriptions concluded prior to that date shall be subject to the terms and conditions in effect at that time, which may be provided by Provenant Cloud upon request at via <a href="mailto:info@provenant.nl">info@provenant.nl</a>)`,
          },
        ],
      },
    ],
  };

  return (
    <>
      <Header />
      <CenterWrapper backgroundColor="grey">
        <div className="py-[24px] px-[20px] lg:py-[76px] lg:px-[84px]">
          {isShowBreadcrumbs && parentTitle && (
            <BreadCrumbs>
              <BreadCrumbs.Item value={parentTitle} url={url} />
              <BreadCrumbs.Item value="General Terms and Conditions" />
            </BreadCrumbs>
          )}
          <p className="typography-h600-bold text-n-900">
            General Terms and Conditions
          </p>
          <p className="typography-h400-bold text-n-900 mt-[16px] lg:mt-[40px] mb-[12px]">
            Effective Date: {tncData?.effectiveDate}
          </p>
          {tncData?.intro?.map((introItem) => (
            <p className="typography-h340 text-n-300 mb-[8px] whitespace-pre-wrap">
              {introItem}
            </p>
          ))}

          {tncData?.terms?.map((term, index) => (
            <>
              <p className="typography-h400-bold text-n-900 mt-[40px] mb-[8px]">
                <span className="mr-[8px]">{index + 1}.</span> {term?.title}
              </p>
              {term.categories?.map((category, subIndex) => (
                <div className="pl-[24px]">
                  <GenerateTerm
                    category={category}
                    prefix={`${index + 1}.${subIndex + 1}.`}
                  />
                </div>
              ))}
            </>
          ))}
        </div>
      </CenterWrapper>
      <Footer />
    </>
  );
};

export default TnC;
