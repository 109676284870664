// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-international-phone-country-selector-button {
  height: 56px !important;
  border-radius: 12px 0 0 12px !important;
  border: 1px solid var(--n-700);
}

.react-international-phone-input {
  width: 100%;
  height: 56px !important;
  border-radius: 0 12px 12px 0 !important;
  border: solid 1px var(--n-700) !important;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/phone-number.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,uCAAA;EACA,8BAAA;AACF;;AAEA;EACE,WAAA;EACA,uBAAA;EACA,uCAAA;EACA,yCAAA;EACA,iBAAA;EACA,gBAAA;EACA,0BAAA;AACF","sourcesContent":[".react-international-phone-country-selector-button {\n  height: 56px !important;\n  border-radius: 12px 0 0 12px !important;\n  border: 1px solid var(--n-700);\n}\n\n.react-international-phone-input {\n  width: 100%;\n  height: 56px !important;\n  border-radius: 0 12px 12px 0 !important;\n  border: solid 1px var(--n-700) !important;\n  line-height: 24px;\n  font-weight: 400;\n  font-size: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
