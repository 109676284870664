import React, { useState } from "react";

import ctl from "@netlify/classnames-template-literals";

import useClickOutside from "../hooks/useClickOutside";
import SVGIcon from "../design-system/SVGIcon";

import {
  IContext,
  IInlineDialog,
  IMenuItem,
  IMenuItems,
  ITrigger,
} from "./interface/InlineDialogInterface";

const DialogContext = React.createContext<IContext | null>(null);
DialogContext.displayName = "DialogContext";

export const useInlineDialog = () => React.useContext(DialogContext);

function InlineDialog({
  children,
  customClass = "",
  isBlock,
  withPosition = true,
  useHover = false,
}: IInlineDialog) {
  const [open, setOpen] = useState(false);

  const ref = React.useRef<HTMLHeadingElement>(null);

  useClickOutside(ref, () => {
    setOpen && setOpen(false);
  });

  const contextValue = {
    open,
    setOpen: () => setOpen(!open),
    ref,
  };

  const dialogCN = ctl(`
    ${customClass ? customClass : ""}
    ${withPosition ? "relative" : ""}
    ${isBlock ? "block" : "inline-block"} text-left
  `);

  const onHover = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!useHover) return;
    setOpen && setOpen(!open);
  };

  return (
    <DialogContext.Provider value={contextValue}>
      <div
        ref={ref}
        className={dialogCN}
        onMouseEnter={onHover}
        onMouseLeave={onHover}
      >
        {children}
      </div>
    </DialogContext.Provider>
  );
}

export const useDialog = () => React.useContext(DialogContext);

function MenuItems({
  children,
  position,
  vPosition = "bottom",
  options,
  customClass = "",
  maxHeightWrapper = 17,
  minHeightWrapper,
  open,
  flexDirectionClass = "",
}: IMenuItems) {
  // get data from props if context not found or undefined
  const context = useDialog();
  const { open: rootOpen } = context || {};

  const isOpen = open ?? rootOpen;

  const localRef = React.useRef<HTMLHeadingElement>(null);

  if (!isOpen) return null;

  const MenuCN = ({
    position,
    vPosition,
    customClass,
  }: {
    position?: "left" | "middle" | "right";
    vPosition?: "top" | "bottom" | "relative" | "relative-to-bottom";
    customClass: string;
  }) => {
    return ctl(`
      absolute
      z-10
      mt-[2px]
      origin-top-right
      items-center
      rounded-[16px]
      bg-n-000
      p-[20px]
      shadow-raised
      ${customClass && customClass}
      ${vPosition === "relative" && "top-0"}
      ${vPosition === "relative-to-bottom" && "bottom-0"}
      ${vPosition === "top" && "top-0 -translate-y-full"}
      ${vPosition === "bottom" && "bottom-0 translate-y-full"}
      ${position === "left" && "left-0"}
      ${position === "right" && "right-0"}
      ${position === "middle" && "left-0 -translate-x-1/2"}
      `);
  };

  return (
    <div
      className={MenuCN({ position, vPosition, customClass })}
      ref={localRef}
    >
      <div
        id="inline-dialog-wrapper"
        className={`hover:overflow-y-overlay overflow-hidden py-[1px] hover:overflow-y-auto flex flex-wrap ${flexDirectionClass}`}
        style={{
          maxHeight: `${maxHeightWrapper}rem`,
          minHeight: `${minHeightWrapper}rem`,
        }}
      >
        {options
          ? options?.map(({ ...props }, index) => (
              <MenuItem {...props} key={index} index={index} />
            ))
          : children}
      </div>
    </div>
  );
}

function MenuItem({
  children,
  selected = false,
  onClick,
  customClass = "",
  disabled = false,
  index,
  useHover = true,
}: IMenuItem) {
  const isDisabled = disabled && !selected; // if selected, should not be disabled to unselect

  const MenuItemCN = (customClass: string) => {
    return ctl(`
    flex 
    min-w-[16rem]
    items-center
    justify-between
    ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
    ${useHover && (isDisabled ? "bg-n-200" : "hover:bg-b-100")}
    ${selected && "bg-b-100"}
    ${customClass}
    :focus-visible:bg-b-100
  `);
  };

  const { setOpen } = useDialog() || {};

  const _onClick = (e: React.MouseEvent<HTMLElement>) => {
    onClick && !isDisabled && onClick(e);
    setOpen && setOpen();
  };

  return (
    <li
      onClick={_onClick}
      className={MenuItemCN(customClass)}
      id={`inline-dialog-item-${index}`}
      tabIndex={index}
    >
      <div
        className="flex w-full items-center justify-between"
        data-cy="inline-dialog-item"
      >
        {children && children}
      </div>
    </li>
  );
}

function Trigger({
  children,
  disabled,
  dataCy,
  customClass,
  useArrow,
}: ITrigger) {
  const triggerCN = ctl(`
    relative
    ${!disabled ? "cursor-pointer" : "cursor-not-allowed"}
    ${customClass}
  `);

  const { open, setOpen } = useDialog() || {};

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!disabled) {
      setOpen && setOpen();
    }
  };

  return (
    <div className={triggerCN} onClick={onClick} data-cy={dataCy}>
      <div className="flex gap-[8px] items-center">
        {children}

        {useArrow && (
          <SVGIcon
            iconName={open ? "icon-arrow-up" : "icon-arrow-down"}
            size={20}
            fillColor="var(--n-700)"
          />
        )}
      </div>
    </div>
  );
}

InlineDialog.displayName = "InlineDialog";

InlineDialog.Trigger = Trigger;
InlineDialog.MenuItem = MenuItem;
InlineDialog.MenuItems = MenuItems;

export default InlineDialog;
