import { useState } from "react";

import SVGIcon from "../../design-system/SVGIcon";

interface IFaqItem {
  question: string;
  answer: string;
}

const FaqItem = ({ faq, index }: { faq: IFaqItem; index: number }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div key={faq?.question + index}>
      <div className="flex items-center w-[calc(100vw-40px)] lg:w-fit">
        <p className="typography-h340-bold text-n-700 mr-[20px] w-full">
          {faq?.question}
        </p>
        <SVGIcon
          iconName={open ? "icon-arrow-up" : "icon-arrow-down"}
          size={20}
          fillColor="var(--n-700)"
          onClick={() => setOpen(!open)}
        />
      </div>
      {open && (
        <p className="typography-h340 text-n-700 max-w-[632px]">
          {faq?.answer}
        </p>
      )}
    </div>
  );
};

export default FaqItem;
