import ctl from "@netlify/classnames-template-literals";

const CenterWrapper = ({
  backgroundColor = "white",
  children,
}: {
  backgroundColor?: string;
  children: any;
}) => {
  const WrapperCN = ctl(`
    ${backgroundColor === "grey" && "bg-n-100"}
    ${backgroundColor === "white" && "bg-n-000"}
    ${backgroundColor === "purple" && "bg-p-400"}
  `);

  return (
    <div className={WrapperCN}>
      <div className="max-w-[90rem] m-auto">{children}</div>
    </div>
  );
};

export default CenterWrapper;
