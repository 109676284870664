import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ServiceTemplate from "../../components/ServiceTemplate";

const Outsourcing = () => {
  const introData = {
    icon: "/assets/svg/service/outsourcing.gif",
    title: "Software Development Outsourcing",
    topDesc:
      "Don't let software development challenges hold you back from achieving your business goals",
    bottomDesc:
      "Are you ready to take your business to the next level without breaking a sweat? You're not just getting an outsourcing service; you're gaining a strategic partner dedicated to skyrocketing your success!",
    illustration: "/assets/svg/service/outsourcing-illus.svg",
  };

  const serviceDetails = [
    {
      image: "/assets/svg/service/cost-efficient.svg",
      desc: "We understand the importance of maximizing your ROI, which is why we offer competitive pricing without compromising on quality. You'll enjoy cost-effective solutions that deliver exceptional results, allowing you to do more with less.",
      name: "Cost-Efficiency",
    },
    {
      image: "/assets/svg/service/quality-assurance.svg",
      desc: "Our commitment to quality is unwavering. From rigorous testing protocols to code reviews and beyond, we leave no stone unturned in our quest for perfection. You can rest easy knowing your software is in the hands of meticulous professionals who take pride in delivering nothing but the best.",
      name: "Quality Assurance",
    },
    {
      image: "/assets/svg/service/agile-collab.svg",
      desc: "Collaboration is at the heart of everything we do. Our agile approach to software development ensures seamless communication, transparency, and flexibility throughout the entire project lifecycle. Whether you're across the street or across the globe, you'll feel like we're right there with you every step of the way.",
      name: "Agile Collaboration",
    },
  ];

  return (
    <>
      <Header />
      <ServiceTemplate introData={introData} serviceDetails={serviceDetails} />
      <Footer />
    </>
  );
};

export default Outsourcing;
