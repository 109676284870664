import { useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SVGIcon from "../design-system/SVGIcon";
import CenterWrapper from "../design-system/CenterWrapper";

interface IBadge {
  icon: string;
  title: string;
}

interface IVacancy {
  badges: IBadge[];
  position: string;
  desc: string;
}

const EmptyStateVacancy = () => {
  return (
    <div className="flex items-center justify-center w-full h-[400px]">
      <div className="flex flex-col items-center w-[calc(100vw-40px)] lg:w-[44.5rem]">
        <img
          src="/assets/svg/not-found.svg"
          alt="not found"
          className="w-[116px]"
        />
        <p className="typography-h400-bold text-n-700 mt-[42px]">
          Currently we don’t have any openings
        </p>
        <p className="typography-h200 text-n-300 mt-[8px] text-center">
          Thank you for reaching out! While we don't have any openings right
          now, we encourage you to check back periodically for future
          opportunities.
        </p>
      </div>
    </div>
  );
};

const VacancyCard = ({ vacancy }: { vacancy: IVacancy }) => {
  const [isHover, setHover] = useState(false);
  return (
    <div className="max-w-[calc(100vw-80px)] lg:max-w-[324px] max-h-[204px] border border-solid border-p-000 bg-n-000 rounded-[12px] p-[20px] flex flex-col gap-[12px]">
      {vacancy?.badges?.map((badge: IBadge) => (
        <div className="flex items-center">
          <SVGIcon iconName={badge?.icon} size={16} fillColor="var(--n-300)" />
          <p className="typography-h200 text-n-700 ml-[4px]">{badge?.title}</p>
        </div>
      ))}
      <p className="typography-h400-bold text-n-700 truncate">
        {vacancy?.position}
      </p>
      <p className="typography-h200 text-n-700 line-clamp-4">{vacancy?.desc}</p>
      <div
        className="flex items-center gap-[4px] group cursor-pointer w-fit"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <p className="typography-h300-bold text-n-700 group-hover:text-b-400">
          Check it out
        </p>
        <SVGIcon
          iconName="icon-arrow-right"
          size={16}
          fillColor={isHover ? "var(--b-400)" : "var(--n-700)"}
        />
      </div>
    </div>
  );
};

const Vacancies = () => {
  // const listVacancy = [
  //   {
  //     badges: [
  //       {
  //         icon: "icon-wrench",
  //         title: "Development",
  //       },
  //     ],
  //     position: "Marketing Communication",
  //     desc: "We are looking for a Sr. React developer. Are you the expert to help us improve Provenant? Apply now!",
  //   },
  //   {
  //     badges: [
  //       {
  //         icon: "icon-wrench",
  //         title: "Development",
  //       },
  //     ],
  //     position: "Marketing Communication",
  //     desc: "We are looking for a Sr. React developer. Are you the expert to help us improve Provenant? Apply now!",
  //   },
  //   {
  //     badges: [
  //       {
  //         icon: "icon-wrench",
  //         title: "Development",
  //       },
  //     ],
  //     position: "Marketing Communication",
  //     desc: "We are looking for a Sr. React developer. Are you the expert to help us improve Provenant? Apply now!",
  //   },
  //   {
  //     badges: [
  //       {
  //         icon: "icon-wrench",
  //         title: "Development",
  //       },
  //     ],
  //     position: "Marketing Communication",
  //     desc: "We are looking for a Sr. React developer. Are you the expert to help us improve Provenant? Apply now!",
  //   },
  //   {
  //     badges: [
  //       {
  //         icon: "icon-wrench",
  //         title: "Development",
  //       },
  //     ],
  //     position: "Marketing Communication",
  //     desc: "We are looking for a Sr. React developer. Are you the expert to help us improve Provenant? Apply now!",
  //   },
  // ];

  const listVacancy: any[] = [];
  return (
    <>
      <Header />
      <div className="bg-n-100">
        {/* intro */}
        <CenterWrapper backgroundColor="grey">
          <div className="flex flex-col-reverse lg:flex-row justify-between">
            <div className="flex flex-col pt-[28px] pl-[24px] pr-[24px] lg:pt-[112px] lg:pl-[4.5rem] lg:pr-0 w-[calc(100vw-40px)] lg:w-[44.5rem]">
              <p className="typography-h600-bold text-n-700 mb-[16px]">
                Work at Provenant
              </p>
              <p className="typography-h300-bold text-n-700 mb-[24px]">
                We are very ambitious, we expect that from you too.
              </p>
              <p className="typography-h300 text-n-300">
                Join an energetic team, which creates a fantastic product
                through close cooperation, a good atmosphere and a little too
                much coffee.
              </p>
            </div>
            <img
              src="/assets/svg/vacancy/vacancies-1.png"
              alt="work at provenant"
            />
          </div>
        </CenterWrapper>

        {/* vacancies */}
        <CenterWrapper backgroundColor="grey">
          <div className="pl-[20px] pb-[36px] pr-[20px] lg:pl-[4.5rem] lg:pb-[104px] lg:pr-[100px]">
            <p className="typography-h600-bold text-n-700 mt-[36px] lg:mt-[46px] mb-[24px]">
              Vacancies
            </p>
            <div
              className={
                listVacancy?.length > 0
                  ? "grid gap-[16px] grid-rows-1 lg:grid-cols-4"
                  : ""
              }
            >
              {listVacancy?.length > 0 &&
                listVacancy?.map((vacancy) => (
                  <VacancyCard vacancy={vacancy} />
                ))}
              {listVacancy?.length === 0 && <EmptyStateVacancy />}
            </div>
          </div>
        </CenterWrapper>

        {/* bottom */}
        <CenterWrapper backgroundColor="purple">
          <div className="flex flex-col-reverse lg:flex-row justify-between bg-p-400">
            <div className="flex flex-col pt-[28px] pl-[20px] pr-[20px] pb-[28px] lg:pt-[112px] lg:pl-[4.5rem] lg:pr-[20px] lg:pb-0 w-[calc(100vw-40px)] lg:w-[44.5rem]">
              <p className="typography-h400-bold text-n-000">
                Did not find what you are looking for? Do not worry
              </p>
              <p className="typography-h300 text-n-200 mb-[24px]">
                Send us your resume and CV and we are going to give you a call
                if we find something that suits our need
              </p>
              <p className="typography-h400-bold text-n-000 text-center lg:text-left">
                career@provenant.com
              </p>
            </div>
            <img
              src="/assets/svg/vacancy/vacancies-1.png"
              alt="work at provenant"
            />
          </div>
        </CenterWrapper>
      </div>
      <Footer />
    </>
  );
};

export default Vacancies;
