import { useState } from "react";

import apiClient from "../hooks/apiClient";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SVGIcon from "../design-system/SVGIcon";
import Textarea from "../design-system/Textarea";
import Button from "../design-system/Button";
import CenterWrapper from "../design-system/CenterWrapper";
import ToastMessage, { INotifData } from "../components/ToastMessage";
import InputField from "../design-system/InputField";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "../styles/phone-number.scss";

interface IForm {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  companyName?: string;
  message?: string;
}

const GenerateLeftContent = () => {
  const partnerList = [
    {
      icon: "icon-marketing",
      title: "Affiliate Partner",
      desc: "As an affiliate partner, you offer Provenant through certain marketing or word of mouth to your target group. Think of blogs, social posts, podcasts, etc. Through a unique links you can apply leads and ultimately customers for which you receive a commission.",
    },
    {
      icon: "icon-api",
      title: "Integration Partner",
      desc: "As an integration partner, we share a link between your software and Provenant's via API. With the aim of simplifying the work processes of the joint customer.",
    },
    {
      icon: "icon-support",
      title: "Implementation Partner",
      desc: "As an implementation partner, you have an excellent knowledge of Provenant and advise your customers about Provenant and help where necessary with the implementation and management.",
    },
    {
      icon: "icon-scaleup",
      title: "Value Added Reseller",
      desc: "As a Value Added Reseller, you use Provenant, in addition to your service(s), to offer a total solution to the end customer. We are part of the total proposition, where you as a partner maintain contact with the customer",
    },
  ];

  return (
    <div className="flex flex-col gap-[40px] w-[calc(100vw-40px)] lg:w-[44.5rem]">
      <div className="flex flex-col">
        <p className="typography-h600-bold text-n-700 mb-[16px]">
          Become a partner
        </p>
        <p className="typography-h300 text-n-300 mb-[24px]">
          We believe in collaboration. That is why we have different
          partnerships to serve our customers as well as possible:
        </p>
        <p className="typography-h300 text-n-300">• Affiliate partner</p>
        <p className="typography-h300 text-n-300">• Integration partner</p>
        <p className="typography-h300 text-n-300">• Implementation partner</p>
        <p className="typography-h300 text-n-300">• Value Added Reseller</p>
        <p className="typography-h300 text-n-300 mt-[24px]">
          Do you want to become a partner? Enter your details in the form on the
          right and indicate which partnership you are interested in. Then we
          will contact you.
        </p>
      </div>

      {partnerList?.map((partner) => (
        <div className="flex flex-col gap-[16px]">
          <div className="flex items-center gap-[16px]">
            <SVGIcon
              iconName={partner.icon}
              size={36}
              fillColor="var(--p-400)"
            />
            <p className="typography-h500-bold text-n-700">{partner.title}</p>
          </div>
          <p className="typography-h300 text-n-300">{partner.desc}</p>
        </div>
      ))}
    </div>
  );
};

const FormPartner = () => {
  const [data, setData] = useState<IForm>({});
  const [errorMessage, setErrorMessage] = useState<IForm>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notifData, setNotifData] = useState<INotifData>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isWringFormatEmail = (email: string) => {
    const Regex =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !Regex.test(email);
  };

  const isWrongFormatEmail = isWringFormatEmail(data.email || "");

  const checkAbleToSubmit = () => {
    const isAllFiled = Object.values(errorMessage).every((x) => x === null);
    return isAllFiled && !isWrongFormatEmail;
  };

  const onChangeField = (key: string, val: string) => {
    setData({ ...data, [key]: val });
    setErrorMessage({ ...errorMessage, [key]: generateErrorMessage(key, val) });
  };

  const generateErrorMessage = (key: string, val: string) => {
    //@ts-ignore
    if (val === "") return "This field is require";
    if (key === "email" && isWrongFormatEmail) {
      return "Oops..wrong format, please check again";
    }
    return null;
  };

  const submit = async () => {
    setIsLoading(true);
    const { isSuccess } = await apiClient(
      "api/v1/partner_request",
      "POST",
      data
    );
    if (isSuccess) {
      setData({});
    }
    setIsLoading(false);
    setNotifData({
      warning: !isSuccess,
      message: isSuccess
        ? "Your message is on its way! We appreciate you reaching out and will get back to you shortly."
        : `Uh oh! There seems to be a problem with your message. Don't worry, our team is on it, and we'll get things working smoothly ASAP.`,
    });
    setTimeout(() => {
      setNotifData({ warning: false, message: null });
    }, 3000);

    // setNotifData({
    //   warning: !isSuccess,
    //   message: isSuccess
    //     ? "Your message is on its way! We appreciate you reaching out and will get back to you shortly."
    //     : `Uh oh! There seems to be a problem with your message. Don't worry, our team is on it, and we'll get things working smoothly ASAP.`,
    // });
    // setTimeout(() => {
    //   setNotifData({ warning: false, message: null });
    // }, 3000);
  };

  return (
    <div className="bg-n-000 rounded-[20px] p-[16px] lg: p-[40px] flex flex-col gap-[10px] h-fit mt-[24px] lg:mt-0">
      {notifData?.message && (
        <ToastMessage
          warning={notifData?.warning || false}
          message={notifData?.message}
          showNotif={true}
        />
      )}
      <div className="flex flex-col lg:flex-row gap-[16px]">
        <InputField
          value={data?.firstname || ""}
          onChange={(val) => onChangeField("firstname", val)}
          onBlur={(val) => onChangeField("firstname", val)}
          placeholder="First Name*"
          customClass="h-[22px]"
          errorMessage={errorMessage?.firstname}
        />
        <InputField
          value={data?.lastname || ""}
          onChange={(val) => onChangeField("lastname", val)}
          onBlur={(val) => onChangeField("lastname", val)}
          placeholder="Last Name*"
          customClass="h-[22px]"
          errorMessage={errorMessage?.lastname}
        />
      </div>
      <InputField
        value={data?.email || ""}
        onChange={(val) => onChangeField("email", val)}
        onBlur={(val) => onChangeField("email", val)}
        placeholder="Work Email Address*"
        customClass="h-[22px]"
        errorMessage={errorMessage?.email}
      />
      <div>
        <PhoneInput
          defaultCountry={navigator.language}
          value={data?.phone}
          onChange={(phone) => {
            onChangeField("phone", phone);
          }}
        />
        {errorMessage?.phone && (
          <p className="typography-h100 text-r-400 mt-[4px]">
            {errorMessage?.phone}
          </p>
        )}
      </div>

      <InputField
        value={data?.companyName || ""}
        onChange={(val) => onChangeField("companyName", val)}
        onBlur={(val) => onChangeField("companyName", val)}
        placeholder="Company Name*"
        customClass="h-[22px]"
        errorMessage={errorMessage?.companyName}
      />

      <Textarea
        value={data?.message || ""}
        onChange={(message) => onChangeField("message", message)}
        onBlur={(message) => onChangeField("message", message)}
        placeholder="Type your Message here"
        customClass="w-[calc(100vw-160px)] lg:w-[29.75rem] h-[144px]"
        errorMessage={errorMessage?.message}
      />

      <Button
        id="Submit"
        customClass="w-full h-[62px]"
        disabled={!checkAbleToSubmit() || isLoading}
        onClick={submit}
      >
        Send Message
      </Button>
    </div>
  );
};

const Partner = () => {
  return (
    <>
      <Header />
      <CenterWrapper backgroundColor="grey">
        <div className="bg-n-100 pt-[32px] pl-[20px] pr-[20px] pb-[56px] lg:pt-[76px] lg:pl-[8.25rem] lg:pr-[52px] lg:pb-[112px] flex flex-col lg:flex-row justify-between">
          <GenerateLeftContent />
          <FormPartner />
        </div>
      </CenterWrapper>
      <Footer />
    </>
  );
};

export default Partner;
