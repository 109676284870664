import axios from "axios";

import { toSnakeCase, toCamelCase } from "./useCaseConverter";

const Axios = axios.create();

const URL =
  process.env.NODE_ENV === "production"
    ? "https://api.provenantcloud.com"
    : "https://staging-api.provenantcloud.com";

const apiClient = (endpoint: string, method: string, body: any) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
    url: `${URL}/${endpoint}`,
    method: method,
    data: toSnakeCase(body),
    withCredentials: false,
  };

  const onSuccess = (r: any) => {
    let data = r.data.data != null ? r.data.data : r.data.properties;

    data = toCamelCase(data);

    return {
      isSuccess: true,
      error: null,
      data,
    };
  };

  const onError = (error: any) => {
    return { isSuccess: false, data: null, pagination: null, error: error };
  };

  return Axios(config).then(onSuccess).catch(onError);
};

export default apiClient;
