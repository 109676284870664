const useBreadcrumbs = () => {
  const usp = new URLSearchParams(window.location.search);
  const url = usp.get("source");

  const getBreadcrumbsTitle = () => {
    switch (url) {
      case "general-terms-and-conditions":
        return `General Terms and Conditions`;
      case "privacy-statement":
        return `Privacy Policy`;
      case "processor-agreement":
        return `Data Processing Agreement`;
    }
  };

  const title = getBreadcrumbsTitle();

  return {
    parentTitle: title,
    url: `/en/${url}`,
    isShowBreadcrumbs: !!url,
  };
};

export default useBreadcrumbs;
