import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ServiceTemplate from "../../components/ServiceTemplate";

const CustomSoftware = () => {
  const introData = {
    icon: "/assets/svg/service/software.gif",
    title: "Custom Software Development",
    topDesc: "Transforms your business with laser-focused custom development",
    bottomDesc:
      "Imagine having a team of expert developers at your fingertips, ready to craft the perfect software solution to tackle your most pressing challenges. Streamline operations, automate tasks, and unlock hidden efficiencies",
    illustration: "/assets/svg/product/order-collaboration-illus.svg",
  };

  const serviceDetails = [
    {
      image: "/assets/svg/service/scalability.svg",
      desc: "As your business grows and evolves, so too should your software. That's why we design our custom solutions with scalability and flexibility in mind, allowing your software to grow and adapt alongside your business without missing a beat.",
      name: "Scalability and Flexibility",
    },
    {
      image: "/assets/svg/service/expert-development.svg",
      desc: "Our team of seasoned developers are masters of their craft, with years of experience in creating cutting-edge software solutions. From initial concept to final execution, our experts work tirelessly to bring your vision to life, ensuring the end result exceeds your expectations.",
      name: "Expert Development Team",
    },
    {
      image: "/assets/svg/service/tailord-solutions.svg",
      desc: "We understand that your business is one-of-a-kind, which is why we offer bespoke software development tailored to your specific requirements. No more settling for off-the-shelf solutions that don't quite cut it. With us, you get software that's unique",
      name: "Tailored Solutions",
    },
  ];

  return (
    <>
      <Header />
      <ServiceTemplate introData={introData} serviceDetails={serviceDetails} />
      <Footer />
    </>
  );
};

export default CustomSoftware;
