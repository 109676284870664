import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BreadCrumbs from "../../design-system/Breadcrumbs";
import CenterWrapper from "../../design-system/CenterWrapper";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";

interface ICategories {
  desc: string;
  subDesc?: string[];
}

const GenerateDPAItem = ({
  subAgreement,
  prefix,
}: {
  subAgreement: ICategories;
  prefix: string;
}) => {
  return (
    <>
      <div className="flex mb-[16px]">
        <span className="typography-h340 text-n-300 min-w-[24px]">
          {prefix}
        </span>
        <p
          className="typography-h340 text-n-300 ml-[24px] whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: subAgreement?.desc }}
        />
      </div>
      {subAgreement?.subDesc?.map((agreement, index) => (
        <div className="flex ml-[24px] ">
          <span className="mr-[8px] typography-h340 text-n-300 min-w-[36px]">
            {prefix}
            {index + 1}.
          </span>
          <p
            className="typography-h340 text-n-300 mb-[8px] ml-[24px] whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: agreement }}
          />
        </div>
      ))}
    </>
  );
};

const DPA = () => {
  const { isShowBreadcrumbs, parentTitle, url } = useBreadcrumbs();

  const dpaData = {
    effectiveDate: "1 April 2024",
    intro: [
      `This Data Processing Agreement (hereinafter referred to as “DPA”) complements the General Terms and Conditions of Provenant B.V. that can be found at <a href="/en/general-terms-and-conditions?source=processor-agreement">https://provenantcloud.com/en/general-terms-and-conditions</a>  (hereinafter referred to as “Provenant”) in their current version between the Customer and Provenant or any other agreement between the Customer and Provenant that governs the use of the Services (hereinafter the “Agreement”). This DPA is an agreement between you and the entity you represent (hereinafter “Customer,” “You,” or “Your”) and Provenant. Unless otherwise defined in this DPA or the Agreement, all terms used in this DPA shall have the meanings assigned to them in Article 1 of this DPA.`,
      `The Customer and Provenant are collectively referred to as “Parties” hereunder, and individually as “Party.”`,
      `In consideration of the following:`,
      `1.	Provenant is a company that, under the brand Provenant, together with its subsidiaries (“Affiliates”), develops, operates, and maintains a Software-as-a-Service platform that provides on-demand services (“Services”) in the cloud on a subscription basis (the “Provenant Cloud Platform”). Provenant acts as a Data Processor.
        2.	The Parties have entered into an Agreement regarding the access of the Data Controller to and the use of the Provenant Cloud Platform and any additional services that the Data Processor may provide to the Data Controller.
        3.	The Data Processor Party may, in the course of performing the Agreement, be considered a “Data Processor” within the meaning of the General Data Protection Regulation (Regulation (EU) 2016/679) (hereinafter “GDPR”) when processing personal data within the scope of the Agreement, and the Data Controller may be considered a “Data Controller” within the meaning of the GDPR.
        4.	In light of the obligations arising from the GDPR, the Parties wish to document their rights and obligations in this Agreement in writing.`,
      `The Parties agree as follows:`,
    ],
    agreements: [
      {
        title: `Definitions`,
        subAgreements: [
          {
            desc: `In this Agreement, the terms “personal data,” “data subject,” “special categories of personal data,” “purpose,” “personal data breach,” and “processing” shall have the same meanings as in the GDPR and shall be interpreted in accordance with the GDPR.`,
          },
          {
            desc: `Notwithstanding the above, capitalized terms used in this Agreement, unless otherwise defined in this Agreement, shall have the same meanings as in the Agreement.`,
          },
        ],
      },
      {
        title: `Processing Purposes`,
        subAgreements: [
          {
            desc: `The Data Processor undertakes to process personal data processed in the performance of the Agreement under the terms of this Agreement and in accordance with and pursuant to the GDPR and other applicable laws and regulations regarding the processing of personal data.`,
          },
          {
            desc: `The Data Processor may only process personal data to the extent necessary for the provision of the services described in the Agreement to the Data Controller. The Parties agree that the processing of personal data is necessary for the purpose specified in Annex A to this Agreement (hereinafter “Annex A”).`,
          },
          {
            desc: `The Data Controller shall notify the Data Processor if the purposes specified in Annex A change during the term of this Agreement.`,
          },
          {
            desc: `For internal quality control purposes under the GDPR, the Data Processor may, as part of the performance of the Agreement, use the personal data processed within the scope of the Agreement, including but not limited to, for statistical investigations regarding the quality of its services under the Agreement.`,
          },
        ],
      },
      {
        title: `Processing`,
        subAgreements: [
          {
            desc: `The Data Controller guarantees that the content and use, as well as the allocation for processing of the personal data under the Agreement, are not unlawful and do not infringe the rights of third parties.`,
          },
          {
            desc: `The Data Processor is solely responsible for the proper processing of personal data under this Agreement and within the scope of this Agreement. The Data Processor is not responsible for other processing of personal data by the Data Controller and/or third parties, including but not limited to:`,
            subDesc: [
              `the collection of personal data by the Data Controller;`,
              `(ii) the processing of personal data by the Data Controller for purposes of which the Data Processor has not been informed by the Data Controller;`,
              `(iii) the processing of personal data by third parties not selected by the Data Processor.`,
            ],
          },
          {
            desc: `If the Data Controller changes the processing purpose and/or amends instructions to the Data Processor and/or issues new instructions, and the Data Processor cannot reasonably be expected to follow such change of purpose or instructions for legitimate business reasons, the Parties shall negotiate in good faith to resolve the conflict.`,
          },
          {
            desc: `Upon request by the Data Controller, the Data Processor shall inform the Data Controller of the measures taken by the Data Processor in connection with its obligations under the Agreement, unless already listed in Annex A.`,
          },
          {
            desc: `The obligations of the Data Processor under the Agreement also apply to those who process personal data on behalf of the Data Processor.`,
          },
          {
            desc: `The Data Processor may appoint sub-processors, such as data centers, without the prior consent of the Data Controller, provided that the Data Processor binds these third parties in writing to the conditions specified in this Agreement and monitors compliance with these conditions. An overview of the sub-processors used can be found here: <a href="/en/subprocessors?source=processor-agreement">https://provenantcloud.com/en/subprocessors/</a>`,
          },
        ],
      },
      {
        title: `Confidentiality`,
        subAgreements: [
          {
            desc: `All personal data received by the Data Processor directly or indirectly from the Data Controller shall be treated confidentially by the Data Processor. The Data Processor shall require its employees involved in the performance of the Agreement, whether within or outside their employment contract with these employees, to sign a confidentiality agreement, at a minimum specifying that these employees must maintain confidentiality with respect to personal data.`,
          },
          {
            desc: `This confidentiality obligation does not apply to situations where (i) the Data Controller authorizes the Data Processor to disclose personal data to third parties, (ii) the disclosure of personal data to third parties is necessary for the performance of the Agreement, or (iii) there is a legal obligation to disclose personal data to third parties. If a situation as described in (iii) occurs and it is not legally prohibited, the Data Processor shall notify the Data Controller, consult with the Data Controller about any available remedies, and whether the Data Controller wishes to avail itself of these remedies. All associated costs shall be borne by the Data Controller.`,
          },
        ],
      },
      {
        title: `Security`,
        subAgreements: [
          {
            desc: `The Data Processor takes reasonable technical and organizational measures to protect personal data against loss and unauthorized processing. Further information can be found in Annex A.`,
          },
          {
            desc: `The Data Processor cannot guarantee that the security measures taken are effective under all circumstances. However, the Data Processor undertakes to ensure that the security measures, in view of the state of the art, the sensitivity of the personal data, and the costs associated with the security measures, achieve a level that corresponds to industry standards for identity and access management.`,
          },
        ],
      },
      {
        title: `Incidents`,
        subAgreements: [
          {
            desc: `In the event of a suspected or actual (i) breach of the protection of personal data or (ii) breach of confidentiality (hereinafter collectively referred to as an “Incident”), the Data Processor shall inform the Data Controller immediately, but no later than 36 (thirty-six) hours after becoming aware of the suspected or actual Incident, in the manner described below.`,
          },
          {
            desc: `In the event of an Incident, the Data Processor shall follow the procedure described in Article 2 of the Data Processor’s Services Agreement for an Incident of Severity Levels 1 or 2 to notify the Data Controller. Article 2 of the Data Processor’s Services Agreement provides that the Data Processor, on behalf of the Data Controller, creates a service request, following which the Data Controller regularly receives notifications, including:`,
            subDesc: [
              `Notification that a service request has been created by the customer support of the Data Processor in the service reporting portal;`,
              `Notification that a service request has been completed by the customer support of the Data Processor in the service reporting portal;`,
              `Regular status updates.`,
            ],
            additionalMessage: `The Data Controller shall be contacted by telephone via the authorized contact person of the Data Controller known to customer support.`,
          },
          {
            desc: `The Data Processor undertakes to take all reasonable necessary measures to prevent or limit (further) breaches of the protection of personal data and/or confidentiality.`,
          },
          {
            desc: `The Data Processor ensures that the information provided to the Data Controller when reporting an Incident is as complete and accurate as possible at that time.`,
          },
          {
            desc: `In the event of an actual breach of the protection of personal data attributable to the Data Processor, the Data Processor shall assist the Data Controller in fulfilling its legal obligation to notify the competent authorities and/or data subjects. If applicable, the Data Processor is entitled to charge the Data Controller reasonable costs for this assistance.`,
          },
          {
            desc: `The Data Processor shall never be liable for the (correct and/or timely fulfilment of) reporting obligations incumbent upon the Data Controller under the GDPR.`,
          },
        ],
      },
      {
        title: `Processing of Personal Data Outside the EEA`,
        subAgreements: [
          {
            desc: `The Data Processor is authorised to process personal data in countries within the European Economic Area (hereinafter the “EEA”) and Switzerland and, if agreed upon between the Parties, in countries that ensure an adequate level of protection pursuant to the GDPR and other applicable regulations regarding the processing of personal data.`,
          },
          {
            desc: `Upon the explicit written request of the Data Controller, the Data Processor may process personal data outside the EEA and outside countries that ensure an adequate level of protection, subject to compliance with the applicable regulations regarding the processing of personal data and subject to an agreement on specific conditions and/or model clauses between the Data Processor and the Data Controller.`,
          },
        ],
      },
      {
        title: `Audit`,
        subAgreements: [
          {
            desc: `The Data Controller has the right to have the organisation of the Data Processor audited by a chartered accountant upon request and under the conditions specified in this Article 8, to determine whether the Data Processor is complying with its obligations under this Agreement.`,
          },
          {
            desc: `All costs of such an audit shall be borne by the Data Controller.`,
          },
          {
            desc: `Prior to an audit, the Parties shall determine in writing whether the results of such an audit shall be monitored and discussed by the Parties or whether the results shall be irrevocably accepted.`,
          },
          {
            desc: `The Data Controller shall ensure that any Party conducting the audit enters into a confidentiality agreement covering all information knowingly or unknowingly disclosed by the Data Processor to a third party or a subcontractor on behalf of that third party during the audit process. The audit must be carried out during normal business hours and must not unduly disrupt the Data Processor’s business operations.`,
          },
          {
            desc: `Upon the Data Controller’s request, the Data Processor shall cooperate in the audit and shall provide the Data Controller with all information reasonably considered relevant within a reasonable period of time. The Parties agree that a period of two (2) weeks is reasonable, unless a compelling reason requires immediate action. The Parties agree that the administrator rights of the Data Processor’s system are excluded from the audit.`,
          },
          {
            desc: `If the Parties cannot agree on the results of an audit, the Parties shall jointly appoint an independent auditor to conduct a further audit to determine whether the Data Processor is complying with its obligations under this Agreement. The independent auditor shall attempt to determine the Data Processor’s compliance with its obligations under this Agreement. The results of such an independent audit shall be binding on both Parties. All costs of such an audit by an independent auditor shall be borne equally by the Parties.`,
          },
        ],
      },
      {
        title: `Data Subjects`,
        subAgreements: [
          {
            desc: `If a data subject, in exercising their rights under the GDPR, contacts the Data Processor, the Data Processor shall refer the data subject to the Data Controller for processing. The Data Controller shall independently handle the data subject’s request and/or complaint.`,
          },
        ],
      },
      {
        title: `Liability`,
        subAgreements: [
          {
            desc: `The liability of the Data Processor under this Agreement is limited. The Data Processor shall not be responsible in any way for the compensation of indirect damages or consequential damages of any kind, including but not limited to damages resulting from loss of revenue or profits, damages resulting from lost savings, damages resulting from delays, damage to reputation, or damages resulting from data loss, whether in the context of an agreement, tort, or any other basis of liability, regardless of whether the possibility of such damages has been pointed out or not.`,
          },
          {
            desc: `Subject to the above, the (cumulative) liability of the Data Processor for any type of damages is limited to a maximum amount (excluding VAT) equal to the fees paid by the Data Controller to the Data Processor under the Agreement in the twelve (12) months preceding the assertion of the claim. In no event shall the total compensation exceed the amount paid by the liability insurance of the Data Processor.`,
          },
          {
            desc: `The limitation of liability of the Data Processor under this Agreement shall only be waived if and to the extent that the damage is attributable to willful intent or gross negligence on the part of the management of the Data Processor. In the event of willful intent or gross negligence, the burden of proof rests with the Data Controller.`,
          },
          {
            desc: `The Data Controller shall indemnify the Data Processor against all claims by third parties, including data subjects, which are made against the Data Processor in connection with the proper processing of personal data under this Agreement.`,
          },
          {
            desc: `A prerequisite for the existence of a claim for damages is that the Data Controller notifies the Data Processor of the damage in writing within thirty (30) days of its discovery by the Data Controller. Any claim for damages caused by the Data Processor shall in any case expire twelve (12) months after the event from which the liability directly or indirectly arises, without prejudice to the provisions of Article 6:89 of the Dutch Civil Code (Nederlandse Burgerlijk Wetboek) being excluded.`,
          },
        ],
      },
      {
        title: `Force Majeure`,
        subAgreements: [
          {
            desc: `The Data Processor shall not be liable for a breach of this Agreement to the extent that such breach is due to force majeure, provided that the Data Processor (i) has notified the Data Controller in writing as soon as reasonably possible and no later than within five (5) working days after the first occurrence of the force majeure event; (ii) has recorded all relevant information about the force majeure event, including its impact and expected duration; and (iii) has taken all reasonable steps to limit the impact.`,
          },
          {
            desc: `For the avoidance of doubt, force majeure includes (but is not limited to) force majeure by suppliers of the Data Processor or other relevant third parties, power outages, general connectivity and internet disruptions, unknown computer viruses, machine malfunctions, acts of war or terrorism, strikes, supply shortages, riots, civil unrest, fires, floods, storms, explosions, natural disasters, government actions, labor conditions, earthquakes, material shortages, or any other cause beyond the reasonable control of the Data Processor or its suppliers, in each case within the meaning of Article 6:75 of the Dutch Civil Code (Nederlandse Burgerlijk Wetboek).`,
          },
          {
            desc: `If, for any reason, the Data Processor is unable to fulfill its obligations under this Agreement due to force majeure, such obligations shall be suspended for as long as the Data Processor is unable to fulfill its obligations due to such force majeure. If the force majeure event lasts longer than thirty (30) days, the Parties agree to negotiate a temporary solution in good faith`,
          },
        ],
      },
      {
        title: `Term and Termination`,
        subAgreements: [
          {
            desc: `This Agreement shall enter into force on the effective date of the Agreement and shall remain in force for the entire term of the Agreement.`,
          },
          {
            desc: `The expiry or termination of the Agreement in accordance with the agreed terms in the relevant service order shall automatically result in the expiry or termination of this Agreement, unless the Data Processor continues to process personal data or has processed personal data for any reason after the termination of the Agreement, in which case the data processing agreement shall remain valid for as long as the Data Processor processes personal data.`,
          },
          {
            desc: `The Data Processor is entitled to terminate this Agreement if the Data Controller makes changes to the purpose of this Agreement as set out in the appendix and/or the instructions, and the Data Processor cannot comply with such changes for operational reasons or considers such changes unacceptable for operational reasons. If such a situation arises, the Parties shall first negotiate in good faith to resolve the matter in a manner acceptable to both Parties.`,
          },
          {
            desc: `Upon termination or expiry of this Agreement (for any reason whatsoever), the Data Processor shall promptly return all personal data to the Data Controller or, at the request of the Data Controller, delete all personal data unless the Parties have agreed otherwise in writing. The Data Processor is not obliged to return personal data that the Data Controller has provided to the Data Processor by means other than those agreed between the Parties.`,
          },
          {
            desc: `Any provision of this Agreement that imposes an obligation after termination (or expiry) of this Agreement, including but not limited to Article 4 (“Confidentiality”), Article 14 of the General Terms and Conditions (“Breach”), and this section, shall remain in effect after termination (or expiry) of this Agreement.`,
          },
        ],
      },
      {
        title: `Miscellaneous`,
        subAgreements: [
          {
            desc: `If any provision of this Agreement is declared invalid or unenforceable, the remaining provisions of this Agreement shall remain in full force, and the invalid or unenforceable provision shall remain in effect to the extent permitted by applicable law.`,
          },
          {
            desc: `This Agreement constitutes the entire agreement between the Parties concerning its subject matter and supersedes all previous written or oral agreements, understandings, or arrangements between the Parties concerning its subject matter.`,
          },
          {
            desc: `The Data Processor is entitled to amend and/or supplement this data processing agreement at any time if necessary to comply with (future) legal provisions. Changes of minor importance, such as obvious typographical errors, obvious omissions, and other similar changes, can be made at any time without the consent of the Data Controller, and the Data Controller is not entitled to terminate the Agreement / Data Processing Agreement.`,
          },
          {
            desc: `Failure or delay in exercising any right or remedy under this Agreement by either Party shall not constitute a waiver of that right or remedy or any other rights or remedies under this Agreement. The exercise of a right or remedy under this Agreement, whether in whole or in part, shall not exclude or limit other rights or remedies under this Agreement.`,
          },
          {
            desc: `This Agreement is binding upon the Parties and their respective successors and authorized representatives and is for the benefit of them.`,
          },
        ],
      },
    ],
  };
  return (
    <>
      <Header />
      <CenterWrapper backgroundColor="grey">
        <div className="py-[24px] px-[20px] lg:py-[76px] lg:px-[84px]">
          {isShowBreadcrumbs && parentTitle && (
            <BreadCrumbs>
              <BreadCrumbs.Item value={parentTitle} url={url} />
              <BreadCrumbs.Item value="Data Processing Agreement" />
            </BreadCrumbs>
          )}
          <p className="typography-h600-bold text-n-900">
            Data Processing Agreement
          </p>
          <p className="typography-h400-bold text-n-900 mt-[16px] lg:mt-[40px] mb-[12px]">
            Effective Date: {dpaData?.effectiveDate}
          </p>
          {dpaData?.intro?.map((introItem) => (
            <p
              className="typography-h340 text-n-300 mb-[8px] whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: introItem }}
            />
          ))}

          {dpaData?.agreements?.map((agreement, index) => (
            <>
              <p className="typography-h400-bold text-n-900 mt-[40px] mb-[8px]">
                <span className="mr-[8px]">{index + 1}.</span>{" "}
                {agreement?.title}
              </p>
              {agreement.subAgreements?.map((subAgreement, subIndex) => (
                <div className="pl-[24px]">
                  <GenerateDPAItem
                    subAgreement={subAgreement}
                    prefix={`${index + 1}.${subIndex + 1}.`}
                  />
                </div>
              ))}
            </>
          ))}
        </div>
      </CenterWrapper>
      <Footer />
    </>
  );
};

export default DPA;
