import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ServiceTemplate from "../../components/ServiceTemplate";

const ITService = () => {
  const introData = {
    icon: "/assets/svg/service/it-service.gif",
    title: "Managed IT Service",
    topDesc:
      "Experience the peace of mind that comes with knowing your technology is in the hands of experts who are dedicated to your success!",
    bottomDesc:
      "Dealing with IT headaches that distract you from growing your business? Say goodbye to technology troubles and hello to peace of mind with Provenant’s managed IT service feature!",
    illustration: "/assets/svg/service/it-service-illus.svg",
  };

  const serviceDetails = [
    {
      image: "/assets/svg/service/enhanced-security.svg",
      desc: "Protecting your business from cyber threats is more important than ever. With Provenant managed IT service, you'll get industry-leading cybersecurity solutions to keep your data safe and secure. From antivirus and firewall protection to regular security audits and threat detection, we've got you covered.",
      name: "Enhanced Security",
    },
    {
      image: "/assets/svg/service/maintenance.svg",
      desc: "Say goodbye to costly downtime and IT emergencies. Our proactive monitoring and maintenance ensure that potential issues are identified and resolved before they can impact your business, keeping your systems running smoothly around the clock.",
      name: "Proactive Maintenance",
    },
    {
      image: "/assets/svg/service/predictable-cost.svg",
      desc: "Say goodbye to unexpected IT expenses that blow your budget. Using managed IT service, you'll enjoy predictable, flat-rate pricing that makes budgeting a breeze. No more surprise bills or hidden fees – just transparent, affordable IT support that fits your budget.",
      name: "Predictable Costs",
    },
  ];

  return (
    <>
      <Header />
      <ServiceTemplate introData={introData} serviceDetails={serviceDetails} />
      <Footer />
    </>
  );
};

export default ITService;
