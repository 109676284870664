import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const Integrated = () => {
  const introData = {
    icon: "/assets/svg/product/integrated.gif",
    title: "Integrated Collaborative Workflow",
    desc: "If you're ready to take your organization to new heights of success, it's time to embrace the power of integrated and collaborative workflow",
    illustration: "/assets/svg/product/integrated-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/integrated.png",
    desc: "Integrated and collaborative workflow is like a well-choreographed dance where different departments, teams, and tools seamlessly come together to achieve common goals. It's about breaking down silos, fostering communication, and streamlining processes to ensure everyone is on the same page and working towards shared objectives",
    provides: [
      {
        icon: "icon-star",
        title: "Transparency and Accountability",
        desc: "With integrated and collaborative workflow, transparency reigns supreme. Everyone has visibility into the status of projects, tasks, and assignments, fostering a culture of accountability where team members take ownership of their work and deadlines.",
      },
      {
        icon: "icon-star",
        title: "Enhanced Communication",
        desc: "Say goodbye to endless email chains and missed messages. Integrated and collaborative workflow platforms provide centralized communication channels where teams can collaborate, share updates, and provide feedback in real-time, ensuring everyone is on the same page and no information falls through the cracks.",
      },
      {
        icon: "icon-star",
        title: "Improved Decision-Making",
        desc: "When information flows freely and teams work together seamlessly, decision-making becomes a breeze. Integrated and collaborative workflow systems provide access to valuable insights and data analytics, empowering decision-makers to make informed choices that drive business success.",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate introData={introData} productDetails={productDetails} />
      <Footer />
    </>
  );
};

export default Integrated;
