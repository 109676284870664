import { ChangeEvent } from "react";

import ctl from "@netlify/classnames-template-literals";

interface IInputField {
  label?: string;
  errorMessage?: string | null;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
  onBlur?: (val: string) => void;
  customClass?: string;
  id?: string;
}

const Textarea = ({
  label,
  errorMessage,
  placeholder,
  value,
  onChange,
  onBlur,
  customClass,
  id,
}: IInputField) => {
  const textareaCN = ctl(`
    bg-n-000
    border border-solid border-n-700 
    active:border-b-400 focus:border-b-400 focus:outline-0
    rounded-[12px]
    px-[20px] py-[16px]
    typography-h300
    ${errorMessage && "border-r-300"}
    ${customClass}
  `);

  return (
    <div>
      {label && <p className="typography-h200 text-n-700">{label}</p>}
      <textarea
        className={textareaCN}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onChange(e?.target?.value)
        }
        onBlur={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onBlur && onBlur(e?.target?.value)
        }
        value={value}
        placeholder={placeholder}
        id={id}
      />
      {errorMessage && (
        <p className="typography-h100 text-r-400 mt-[4px]">{errorMessage}</p>
      )}
    </div>
  );
};

export default Textarea;
