// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-product {
  background: radial-gradient(farthest-corner at 90% 90%, var(--rd-600) -1%, transparent 64%);
}`, "",{"version":3,"sources":["webpack://./src/styles/gradient.scss"],"names":[],"mappings":"AAAA;EACE,2FAAA;AACF","sourcesContent":[".gradient-product {\n  background: radial-gradient(\n    farthest-corner at 90% 90%,\n    var(--rd-600) -1%,\n    transparent 64%\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
