import { useState } from "react";

import SVGIcon from "../../design-system/SVGIcon";

interface IGrouping {
  title: string;
  plans: string[];
}

const PlanCard = ({ group }: { group: IGrouping }) => {
  const { title, plans } = group || {};
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="bg-n-000 px-[10px] py-[20px] rounded-[12px] w-[calc(100vw-60px)] lg:w-[356px]">
      <div className="flex justify-between">
        <p className="typography-h300-bold text-n-700">{title}</p>
        <SVGIcon
          iconName={open ? "icon-arrow-up" : "icon-arrow-down"}
          size={24}
          fillColor="var(--b-400)"
          onClick={() => setOpen(!open)}
        />
      </div>
      {open && (
        <div className="mt-[16px] flex flex-col gap-[12px]">
          {plans?.map((plan) => (
            <div className="flex items-center gap-[12px]">
              <SVGIcon
                iconName="icon-check"
                size={18}
                fillColor="var(--b-400)"
              />
              <p className="typography-h300 text-n-700">{plan}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlanCard;
