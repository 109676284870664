import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const Manufacture = () => {
  const introData = {
    icon: "/assets/svg/home//manufacturing-execution.gif",
    title: "Manufacture",
    desc: "Delivering the Products You Need, When You Need Them",
    illustration: "/assets/svg/product/manufacturing-execution-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/industry/manufacture.png",
    provides: [
      {
        icon: "icon-efficient",
        title: "Production Efficiency",
        desc: "Optimizing IT technology such as implementing Manufacturing Execution Systems (MES) can improve production efficiency by providing real-time visibility into production processes, identifying bottlenecks, and optimizing workflows. Predictive maintenance systems powered by IoT sensors can also reduce downtime by anticipating equipment failures before they occur.",
      },
      {
        icon: "icon-crm",
        title: "Customer Relationship Management (CRM)",
        desc: "Building strong customer relationships is essential for long-term success. CRM systems integrated with manufacturing processes can provide insights into customer preferences, facilitate order management, and enable personalized communication, leading to improved customer satisfaction and loyalty.",
      },
      {
        icon: "icon-security",
        title: "Data Security and Cybersecurity",
        desc: "With increasing digitization, manufacturing businesses become more vulnerable to cyber threats and data breaches. Optimizing IT security measures, including network segmentation, encryption, access controls, and employee training, can safeguard sensitive information and prevent costly security incidents.",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate
        introData={introData}
        productDetails={productDetails}
        type="industry"
      />
      <Footer />
    </>
  );
};

export default Manufacture;
