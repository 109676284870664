import React, { useEffect } from "react";

const useClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      const isPortalClicked = (element: {
        querySelector: (arg0: string) => any;
      }) => {
        const hasPortal = !!element?.querySelector("div.is-portal");
        if (hasPortal) {
          const portalDiv = document.querySelectorAll(
            `[id^=shared-dropdown]`
          )[0];

          return portalDiv.contains(event.target);
        }

        return false;
      };

      const isRefClicked = (r: any) =>
        !r.current ||
        r.current.contains(event.target) ||
        isPortalClicked(r.current);

      if (Array.isArray(ref)) {
        const isClicked = ref.find((r) => isRefClicked(r)) || false;
        if (isClicked) return;
      } else {
        if (isRefClicked(ref)) return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useClickOutside;
