import { useState } from "react";

import { useHistory } from "react-router";

import SVGIcon from "../design-system/SVGIcon";
import Button from "../design-system/Button";

const GenerateMenuItem = ({ header, closeSidebar }: any) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const { name, type, child, redirectUrl } = header;

  const redirectToMenu = (redirectUrl: string) => {
    history.replace(redirectUrl);
    closeSidebar();
  };

  return (
    <div>
      <div
        className="h-[64px] px-[20px] flex items-center justify-between border-0 border-b border-solid border-n-100"
        onClick={() =>
          child?.length > 0 ? setOpen(!open) : redirectToMenu(redirectUrl)
        }
      >
        <p className="typography-h300 text-n-700">{name}</p>
        {type === "dropdown" && (
          <SVGIcon
            iconName={open ? "icon-arrow-up" : "icon-arrow-down"}
            size={20}
          />
        )}
      </div>
      {open &&
        child?.length > 0 &&
        child?.map((childItem: any) => (
          <div
            className="flex items-center gap-[16px] h-[4.5rem] px-[20px]"
            onClick={() => redirectToMenu(childItem?.redirectUrl)}
          >
            <img
              src={childItem?.iconName}
              alt={childItem?.title}
              className="h-[32px] w-[32px]"
            />
            <p className="typography-h300-bold text-n-700 m-0">
              {childItem?.title}
            </p>
          </div>
        ))}
    </div>
  );
};

const HeaderMobile = ({ menuHeader }: any) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <>
      <div className="fixed left-0 top-0 px-[20px] h-[44px] flex items-center justify-between bg-n-000 pt-[16px] w-[calc(100vw-40px)] z-[10]">
        <img
          src="/assets/svg/provenant_logo.svg"
          className="height-auto w-[156px] cursor-pointer"
          alt="provenant logo"
          onClick={() => history.replace("/home")}
        />
        <SVGIcon
          iconName={"icon-menu"}
          size={24}
          fillColor="var(--n-700)"
          onClick={() => setOpen(true)}
        />
      </div>
      {open && (
        <div className="fixed top-0 right-0 h-full w-full bg-n-000 animated-half slideInRight z-[100]">
          <div className="h-[60px] flex items-center gap-[16px] px-[24px]">
            <SVGIcon
              iconName="icon-cancel"
              size={24}
              fillColor="var(--n-700)"
              onClick={() => setOpen(false)}
            />
            <p className="typography-h300 text-n-700">Close</p>
          </div>
          <div className="overflow-y-auto h-[calc(100vh-64px)]">
            {menuHeader?.map((header: any) => (
              <GenerateMenuItem
                header={header}
                closeSidebar={() => setOpen(false)}
              />
            ))}
            <Button
              id="talktosales"
              onClick={() => history.replace("/getintouch")}
              customClass="px-[36px] mt-[40px] mx-[20px]"
            >
              Talk to Sales
            </Button>
          </div>
        </div>
      )}
      <div className="mb-[52px]"></div>
    </>
  );
};

export default HeaderMobile;
