import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const Shipping = () => {
  const introData = {
    icon: "/assets/svg/product/shipping.gif",
    title: "Shipping Management",
    desc: "You're not just getting a service, you're gaining a strategic partner that will revolutionize the way you move goods from point A to point B",
    illustration: "/assets/svg/product/shipping-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/shipping.png",
    desc: "Experience the difference shipping management excellence can make!",
    provides: [
      {
        icon: "icon-star",
        title: "Customer Satisfaction",
        desc: "In today's competitive marketplace, customer satisfaction is paramount. With our feature, you'll exceed your customers' expectations with seamless shipping experiences that leave a lasting impression. From fast delivery times to accurate tracking updates, we go above and beyond to ensure every customer is delighted with their shipping experience.",
      },
      {
        icon: "icon-star",
        title: "Scalability and Flexibility",
        desc: "Whether you're shipping a few packages or managing a global logistics network, we scale to meet your needs. Our flexible platform adapts to your business requirements, allowing you to easily expand into new markets, launch new product lines, and handle seasonal fluctuations in shipping volumes without skipping a beat.",
      },
      {
        icon: "icon-star",
        title: "End-to-End Visibility",
        desc: "Our state-of-the-art tracking and monitoring systems give you real-time visibility into your shipments' status, location, and condition, allowing you to proactively manage exceptions and keep your customers informed every step of the way.",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate introData={introData} productDetails={productDetails} />
      <Footer />
    </>
  );
};

export default Shipping;
