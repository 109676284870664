import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const Invoicing = () => {
  const introData = {
    icon: "/assets/svg/product/invoicing.gif",
    title: "Invoicing",
    desc: "Are you tired of spending hours creating invoices manually? Say goodbye to paperwork and hello to effortless invoicing",
    illustration: "/assets/svg/product/invoicing-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/invoicing.png",
    desc: "Don't let invoicing headaches hold your business back. Talk to our sales about this service and experience the power of effortless, streamlined invoicing that gets you paid faster and keeps your business running smoothly!",
    provides: [
      {
        icon: "icon-star",
        title: "Stay Organized",
        desc: "Keep track of all your invoices in one place with our dashboard. Whether you're managing one client or one hundred, our intuitive interface makes it easy to stay organized and keep tabs on your finances.",
      },
      {
        icon: "icon-star",
        title: "Simplify Invoicing",
        desc: "Creating and sending invoices has never been easier. Our user-friendly interface and customizable templates make it a breeze to generate professional-looking invoices in just a few clicks. Say goodbye to manual data entry and hello to streamlined invoicing!",
      },
      {
        icon: "icon-star",
        title: "Gain Insights",
        desc: "With our powerful reporting tools, you'll gain valuable insights into your business's finances. Track your revenue, monitor outstanding invoices, and identify trends to help you make smarter financial decisions and drive business growth.",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate introData={introData} productDetails={productDetails} />
      <Footer />
    </>
  );
};

export default Invoicing;
