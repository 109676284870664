import Header from "../components/Header";
import Footer from "../components/Footer";

const PageNotFound = () => {
  return (
    <>
      <Header />
      <div className="w-full h-full flex flex-col items-center justify-center bg-n-100 py-[56px]">
        <img
          src="/assets/svg/404.svg"
          alt="404"
          className="max-w-[calc(100vw-40px)] lg:max-w-[720px]"
        />
        <p className="typography-h400-bold text-n-700 mt-[32px] text-center">
          We are Sorry, Something wrong has just happened
        </p>
        <p className="typography-h340 text-n-300 max-w-[calc(100vw-40px)] lg:max-w-[668px] text-center">
          Looks like this page got lost in the internet jungle! Don't worry,
          we'll send out a search party of links to help you find your way back.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default PageNotFound;
