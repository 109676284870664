import React from "react";

import { useHistory } from "react-router";

import SVGIcon from "../design-system/SVGIcon";

const BreadCrumbs = ({
  children,
  isHide,
  className = "",
}: {
  children: React.ReactNode;
  isHide?: boolean;
  className?: string;
}) => {
  if (isHide) return null;
  return (
    <div className={`${className} flex w-full flex-row items-center mb-[48px]`}>
      {children}
    </div>
  );
};

const BreadCrumbsItem = ({
  url,
  value,
}: {
  url?: string;
  value: string | number;
}) => {
  const history = useHistory();
  const redirect = () => {
    if (!url) return;
    history.replace(url);
  };
  return (
    <>
      <SVGIcon
        size={16}
        fillColor="var(--n-700)"
        iconName="icon-arrow-right"
        customClass="mx-[8px] items-center first:hidden"
      />
      <div
        onClick={redirect}
        className={`${
          url ? "text-b-400 cursor-pointer" : "text-n-400 cursor-not-allowed"
        } typography-h340`}
      >
        {value}
      </div>
    </>
  );
};

BreadCrumbs.Item = BreadCrumbsItem;

export default BreadCrumbs;
