import { ChangeEvent } from "react";

interface ICheckbox {
  id: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  disabled?: boolean;
  customContainerClass?: string;
}

const Checkbox = ({
  checked = false,
  onChange,
  value,
  id,
  disabled = false,
  customContainerClass,
}: ICheckbox) => {
  return (
    // <div
    //   className={`single-checkbox-container ${customContainerClass}`}
    //   onClick={(e) => e.stopPropagation()}
    // >
    //   {/* <input
    //     type="checkbox"
    //     checked={checked}
    //     name={id}
    //     value={value}
    //     id={id}
    //     onChange={(e) => {
    //       !disabled && onChange && onChange(e);
    //     }}
    //     disabled={disabled}
    //   /> */}
    //   <label
    //     className="relative flex items-center p-3 rounded-full cursor-pointer"
    //     htmlFor={id}
    //   >
    //     <input
    //       type="checkbox"
    //       className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
    //       checked={checked}
    //       name={id}
    //       value={value}
    //       id={id}
    //       onChange={(e) => {
    //         !disabled && onChange && onChange(e);
    //       }}
    //       disabled={disabled}
    //     />
    //     <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-3.5 w-3.5"
    //         viewBox="0 0 20 20"
    //         fill="currentColor"
    //         stroke="currentColor"
    //         stroke-width="1"
    //       >
    //         <path
    //           fill-rule="evenodd"
    //           d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
    //           clip-rule="evenodd"
    //         ></path>
    //       </svg>
    //     </span>
    //   </label>
    // </div>
    <div className="inline-flex items-center">
      <label
        className="relative flex items-center rounded-full cursor-pointer"
        htmlFor={id}
      >
        <input
          type="checkbox"
          className="before:content[''] peer relative h-[20px] w-[20px] cursor-pointer appearance-none rounded-full border border-solid border-n-300 bg-transparent transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-g-300 before:opacity-0 before:transition-opacity checked:border-g-300 checked:bg-g-300 checked:before:bg-g-300 hover:scale-105 hover:before:opacity-0"
          checked={checked}
          name={id}
          value={value}
          id={id}
          onChange={(e) => {
            !disabled && onChange && onChange(e);
          }}
          disabled={disabled}
        />
        <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="1"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
