const userAgentUtils = () => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  else if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  } else if (/Prerender/.test(userAgent)) {
    return "Crawler";
  }
  return "Web";
};

const checkMobileDevice = () => {
  const platform = userAgentUtils();

  return platform === "iOS" || platform === "Android";
};

export { userAgentUtils, checkMobileDevice };
