const UnderConstruction = () => {
  return (
    <div className="w-full h-[100vh] flex flex-col items-center justify-center bg-n-100 m-auto">
      <img
        src="/assets/svg/under-construction.svg"
        alt="under construction"
        className="max-w-[calc(100vw-40px)] lg:max-w-[720px]"
      />
      <p className="typography-h400-bold text-n-700 mt-[32px] text-center">
        We are Sorry, We are Under Construction
      </p>
      <p className="typography-h340 text-n-300 max-w-[calc(100vw-40px)] lg:max-w-[668px] text-center mx-[20px] lg:mx-[40px] mt-[16px]">
        In the meantime, stay tuned for updates and sneak peeks. We can't wait
        to unveil what we've been working on!
      </p>
    </div>
  );
};

export default UnderConstruction;
